import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AppBarModern } from '../../components/AppBarModern';
import { Oval } from 'react-loader-spinner';
import { FiMail } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { requestPasswordReset } from '../../services/ApiService';
import { ApiResponse } from '../../models/ApiResponse';
import { SignupResponse } from '../../models/auth/AuthModels';
import { toast } from 'react-toastify';

type Form = {
  email: string;
}

const ForgotPassword = () => {

  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const { register, handleSubmit } = useForm<Form>()

  const onSubmit = handleSubmit(({ email }) => {
    setIsLoaded(true)
    handleResetPasswordRequest(email)
  });

  async function handleResetPasswordRequest(email: string) {
    await requestPasswordReset({
      email: email
    })
      .then((response: { data: ApiResponse<SignupResponse> }) => {
        sessionStorage.setItem("email", email)
        toast.success(response.data.message)
        navigate("/reset-password", {
          replace: false
        })
      })
      .catch(function (error) {
        setIsLoaded(false)
        if (error.response)
          toast.error(error.response.data.message + " ")
        else
          toast.error(error.message)
      })
  }

  return (
    <div>
      <Helmet>
        <title>Forgot Password?</title>
      </Helmet>
      <AppBarModern title={"Forgot your password?"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-24 mx-5">
        <div>
          Don’t worry! we will send a verification code to your registered email address.
        </div>

        <form onSubmit={onSubmit} className="loginForm m-auto">

          <div className="relative mt-6">
            <FiMail className="absolute left-4 h-12 text-666"/>
            <input
              type="email"
              placeholder="Email Address"
              autoComplete="username"
              className="input-bg pl-11"
              {...register("email", { required: true })}/>
          </div>

          {isLoaded ?
            <div className="flex justify-center h-12 mt-7">
              <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
            </div>
            :
            <button
              className="input-btn mt-5">
              Continue
            </button>
          }
        </form>

      </main>
    </div>
  );
}

export default ForgotPassword;