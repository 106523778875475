import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input-rc-17';
import { AppBarModern } from '../../components/AppBarModern';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { resetPassword } from '../../services/ApiService';
import { ApiResponse } from '../../models/ApiResponse';
import { SignupResponse } from '../../models/auth/AuthModels';
import { toast } from 'react-toastify';
import { FiLock } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

type Form = {
  password: string;
  confirm_password: string;
}

const ResetPassword = () => {
  const [otp, setOtp] = useState("");
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const { register, handleSubmit } = useForm<Form>()

  function handleChange(otp) {
    setOtp(otp)
  }

  const onSubmit = handleSubmit(({ password, confirm_password }) => {
    setIsLoaded(true)
    handleResetPassword(password, confirm_password)
  });

  async function handleResetPassword(password, confirm_password) {
    let email = sessionStorage.getItem("email")
    let error = ""
    if (!email)
      error = "Please go back and enter your email"
    if (password === "")
      error = "Please enter password"
    else if (confirm_password === "")
      error = "Please enter confirm password"
    else if (password !== confirm_password) {
      error = "Confirm password didn't match"
    } else if (otp.length < 5) {
      error = "Please enter OTP"
    }

    if (error !== "") {
      toast.warn(error)
      setIsLoaded(false)
      return
    }
    setIsLoaded(true)

    await resetPassword({
      otp: parseInt(otp),
      email: email!!,
      password: password
    })
      .then((response: { data: ApiResponse<SignupResponse> }) => {
        toast.success(response.data.message)
        navigate("/login", {
          replace: true
        })
      })
      .catch(function (error) {
        setIsLoaded(false)
        if (error.response)
          toast.error(error.response.data.message + " ")
        else
          toast.error(error.message)
      })
  }

  return (
    <div>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <AppBarModern title={"Help us reset \nyour password"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-24 mx-5">
        <div>
          An OTP has been sent to <span className="font-medium">{sessionStorage.getItem("email")}</span>. Please check
          email inbox (also spam folder)
        </div>

        <form autoComplete="off" onSubmit={onSubmit} className="loginForm m-auto">
          <div className="otp-container flex items-center mb-5">
            <OtpInput
              className="otp-holder mt-5"
              value={otp}
              onChange={handleChange}
              isInputNum={true}
              numInputs={5}
            />
          </div>

          <div className="font-medium color-black text-lg">Set your new password</div>

          <div className="relative mt-2">
            <FiLock className="absolute left-4 h-12 -mt-px text-666"/>
            <input
              type="password"
              placeholder="Password"
              className="input-bg pl-11"
              id="current-password"
              {...register("password", { required: true })}/>
          </div>

          <div className="relative mt-4">
            <FiLock className="absolute left-4 h-12 -mt-px text-666"/>
            <input
              type="password"
              placeholder="Confirm Password"
              className="input-bg pl-11"
              id="confirm-password"
              {...register("confirm_password", { required: true })}/>
          </div>


          {isLoaded ?
            <div className="flex justify-center h-12 mt-7">
              <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
            </div>
            :
            <button
              className="input-btn mt-5">
              Reset Password
            </button>
          }
        </form>

      </main>
    </div>
  );
}

export default ResetPassword;