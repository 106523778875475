import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Android, Apple } from '@mui/icons-material';
import { WebHeader } from '../../components/WebHeader';
import { Link } from 'react-router-dom';

const Landing = () => {

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {

    }
  }, [])

  return (
    <div>
      <Helmet>
        <title>RideMate - Split Fare, Save Money!</title>
      </Helmet>

      <WebHeader/>

      <main>
        <div className="w-max-body flex pt-6 md:pt-14 md:items-center flex-col md:flex-row">

          <div className="flex-1">
            <div className="text-4-5xl md:text-7xl font-bold text-black ">
              Split Fare,
              <div className="h-3"/>
              Save Money!
            </div>

            <div className="text-md md:text-3xl mt-6">
              Ride in cars at the cost of <span className="font-medium">bus & rickshaw</span>,<br/>
              and <span className="font-medium">earn money</span> if you own a car or bike.
            </div>

            <div className="space-20"></div>

            <div className="hide-when-installed">
              <div className="flex mt-6 mb-5 flex-row gap-5">
                <a className="flex-1 md:flex-none" data-applink="market://details?id=club.nsuer.nsuer"
                   href="https://play.google.com/store/apps/details?id=xyz.ridemate" rel="alternate">
                  <div
                    className="leading-5 md:leading-none py-3 rounded-lg text-white pl-4 pr-6 bg-[#78C257] flex items-center inline">
                    <Android className="mr-3"/>
                    <div>
                      Download
                      <br/> on <span className="font-medium">Android</span>
                    </div>
                  </div>
                </a>
                <Link className="flex-1 md:flex-none" to="/ios-installation">
                  <div
                    className="leading-5 md:leading-none py-3 rounded-lg text-white pl-4 pr-6 bg-black flex items-center inline">
                    <Apple className="mr-3"/>
                    <div>
                      Install
                      <br/> on <span className="font-medium">iPhone</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>

          <img className="h-68 md:h-96 mt-5 mx-auto md:mt-0" src="/images/ridemate_creative.svg"/>

        </div>


        <div className="bg-fafafa pt-14 pb-14 mt-14">
          <div className="w-max-body flex md:items-center flex-col md:flex-row">
            <div className="phone-mockup w-68 mx-auto md:mx-0">
              <img src="/images/screenshots/screenshot_home.jpg"/>
            </div>
            <div className="flex-1"/>
            <div className="text-2xl pl-0 md:text-6xl mt-6 md:mt-0 md:pl-10">
              Security is our top priority,
              <br/>
              <span className="font-bold md:font-medium">Verified students</span> only!
              <div className="text-lg md:text-2xl mt-5 md:mt-14">
                Ride with your university students only. <br/>
                Choose your ride mates based on gender.
              </div>
              <div className="text-xl md:text-3xl font-medium mt-5 md:mt-16">
                No outsider, no risk!
              </div>
            </div>
          </div>
        </div>

        <div className="mt-14">
          <div className="w-max-body flex items-center">
            <div className="text-2xl md:text-6xl mt-6 md:mt-0">
              Find ride mates
              <br/>
              in <span className="font-bold md:font-medium">two clicks</span>
              <div className="text-lg md:text-2xl mt-5 md:mt-14">
                Set your home area and time. That's it!<br/>
                RideMate will find match for you.
              </div>
              <div className="text-xl md:text-3xl font-medium mt-5 md:mt-16">
                Enjoy amazing UX!
              </div>
            </div>
            <div className="flex-1"/>
            <div className="phone-mockup w-68">
              <img src="/images/screenshots/screenshot_find.jpg"/>
            </div>
          </div>
        </div>

        <div className="bg-fafafa pt-14 pb-14 mt-14">
          <div className="w-max-body flex md:items-center flex-col md:flex-row">
            <div className="phone-mockup w-68 mx-auto md:mx-0">
              <img src="/images/screenshots/screenshot_chat.jpg"/>
            </div>
            <div className="flex-1"/>
            <div className="text-2xl pl-0 md:text-6xl mt-6 md:mt-0 md:pl-10">
              <span className="font-bold md:font-medium">Live Chat</span> with ride mates
              <div className="text-lg md:text-2xl mt-5 md:mt-14">
                Chat and set up a meeting place inside campus
                or in your area.
              </div>
              <div className="text-xl md:text-3xl font-medium mt-5 md:mt-16">
                Stay Connected!
              </div>
            </div>
          </div>
        </div>

        <div className="mt-14 md:mt-20 mb-10">
          <div className="w-max-body text-center">
            <div className="text-3xl md:text-6xl text-center">
              <span className="font-medium">Request a ride</span> on Uber/Pathao/Jatri<br/>
              And <span className="font-medium">split the fare</span> with ride mates
            </div>

            <div
              className="flex items-center align-middle justify-center brands_col mt-14 mb-20 gap-5 md:gap-14">
              <img className="h-10 md:h-24" src="/images/brands/logo_jatri.svg"/>
              <img className="h-7 md:h-16" src="/images/brands/logo_uber.svg"/>
              <img className="h-10 md:h-24" src="/images/brands/logo_pathao.svg"/>
            </div>

          </div>
        </div>


      </main>


      <div className="pt-20 pb-20 bg-black text-white">
        <div className="w-max-body text-center">
          <Link className="mr-10" to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <br/>
          <br/>
          © Copyright | RideMate 2022
        </div>

      </div>

    </div>
  );
}

export default Landing;