import React, {useEffect, useState} from "react";
import {Global} from '@emotion/react';
import {Puller} from '../../components/Puller';
import TextField from '@mui/material/TextField';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticTimePicker from '@mui/lab/StaticTimePicker';
import {Button, Drawer, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Box from '@mui/material/Box';
import {styled} from '@mui/system';
import moment from 'moment/moment';
import {ArrowDropDown} from '@mui/icons-material';
import {MobileDatePicker} from '@mui/lab';
import DatePickerToolbar from '@mui/lab/DatePicker/DatePickerToolbar';
import {ToolbarComponentProps} from '@mui/lab/internal/pickers/typings/BasePicker';
import {isSameDay} from '../../utils/Utils';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    '& .MuiToggleButtonGroup-grouped': {
        marginRight: "0.5rem",
        textTransform: "none",
        border: "1px solid #eee !important",
        color: "#444",

        '&.Mui-disabled': {
            borderWidth: "1px",
        },
        '&.Mui-selected': {
            borderColor: "#5263FF !important",
            backgroundColor: "#5263FF !important",
            color: "#fff !important",
        },
        '&:not(:first-of-type)': {
            borderRadius: "0.5rem",
        },
        '&:first-of-type': {
            borderRadius: "0.5rem",
        },
    },
}));

interface Props {
    show: boolean,
    onDismiss: any,
    leavingDateSelected: (date: Date) => void,
}

const SelectDateTimeModal = (props: Props) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [timeValue, setTimeValue] = useState<Date | null>(new Date());
    const [dateSelectedValue, setDateSelectedValue] = useState<Date | null>(new Date(),);
    const [dateValue, setDateValue] = useState<Date | null>(new Date(),);
    const [leavingDate, setLeavingDate] = useState<Date>(new Date());

    let isDatePickerClosed = true;
    let isDateCancelClicked = false;
    const [dateType, setDateType] = useState('today');

    useEffect(() => {
        if (timeValue != null && dateSelectedValue != null) {
            const date = new Date(dateSelectedValue)
            const time = new Date(timeValue)
            date.setHours(
                time.getHours(),
                time.getMinutes(),
                time.getSeconds(),
                time.getMilliseconds()
            )
            setLeavingDate(date)
        }
    }, [dateSelectedValue, timeValue])

    const handleFormat = (
        event: React.MouseEvent<HTMLElement>,
        type: string,
    ) => {
        if (type !== null) {
            if (type === "today") {
                setDateType(type);
                setDateSelectedValue(new Date())
            } else if (type === "tomorrow") {
                const today = new Date()
                const tomorrow = new Date(today)
                tomorrow.setDate(tomorrow.getDate() + 1)
                setDateType(type);
                setDateSelectedValue(tomorrow)
            }
        }
    };


    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        overflow: 'visible',
                    },
                    '.PrivateDatePickerToolbar-penIcon': {
                        display: 'none!important',
                    }
                }}
            />

            <Drawer
                variant="temporary"
                anchor="bottom"
                open={props.show}
                onClose={() => {
                    props.onDismiss()
                }}
                draggable={false}
                keepMounted={false}
            >

                <Puller/>

                <div className="mx-5 mt-4">
                    <div className="font-medium text-black text-xl">When are you leaving?</div>

                    <StyledToggleButtonGroup
                        className="mt-2"
                        value={dateType}
                        onChange={handleFormat}
                        exclusive
                    >
                        <ToggleButton className="px-4" value="today">
                            Today
                        </ToggleButton>

                        <ToggleButton className="px-4" value="tomorrow">
                            Tomorrow
                        </ToggleButton>

                        <ToggleButton onClick={() => {
                            // setFormats("custom")
                            if (isDatePickerClosed)
                                setDatePickerOpen(true)
                        }} className="pl-4" value="custom">

              <span>
                 {isSameDay(dateSelectedValue, new Date()) || dateType !== "custom" ? "Select Date" : moment(dateSelectedValue).format('DD MMM yyyy')}
                  <ArrowDropDown className="-mr-1.5"/></span>

                        </ToggleButton>

                    </StyledToggleButtonGroup>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            inputFormat="dd/mm/yyyy"
                            value={dateValue}
                            open={datePickerOpen}
                            disablePast={true}
                            cancelText=""
                            okText=""
                            onOpen={() => {
                                isDateCancelClicked = false
                                isDatePickerClosed = false
                                setDatePickerOpen(true)
                            }}
                            ToolbarComponent={(props) => <div>
                                <DatePickerToolbar {...props as ToolbarComponentProps} />
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        isDatePickerClosed = true
                                        setDatePickerOpen(false)
                                    }}
                                    className="absolute bottom-px8 left-4 z-50">Cancel</Button>
                                <Button
                                    onClick={() => {
                                        isDatePickerClosed = true
                                        setDateSelectedValue(dateValue)
                                        if (!isSameDay(dateValue, new Date())) {
                                            setDateType("custom");
                                        } else {
                                            setDateValue(new Date())
                                        }
                                        setDatePickerOpen(false)
                                    }}
                                    className="absolute bottom-px8 right-4 z-50">Ok</Button>
                            </div>}
                            onClose={() => {
                                isDatePickerClosed = true
                                setDatePickerOpen(false)
                                isDateCancelClicked = false
                            }}
                            onChange={(newValue: Date | null) => {
                                setDateValue(newValue)
                            }}
                            renderInput={(params) => {
                                return <TextField className="hidden" {...params} />
                            }}/>
                    </LocalizationProvider>
                </div>

                <div className="mb-3">
                    <Box sx={{
                        '& .MuiIconButton-sizeMedium': {display: 'none'},
                        '& .PrivateTimePickerToolbar-ampmSelection .Mui-selected ': {color: '#5263FF!important'},
                    }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StaticTimePicker
                                displayStaticWrapperAs="mobile"
                                value={timeValue}
                                onChange={(newValue) => {
                                    setTimeValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                </div>

                <div className="mx-5 mb-6 text-center">Leaving
                    at <b>{moment(leavingDate).format('hh:mm A')}</b> on <b>{moment(leavingDate).format('DD MMM yyyy')}</b>
                </div>

                <div className="flex flex-row">
                    <Button
                        className="flex-1 ml-5 mr-1.5 mb-6 text-black rounded-lg h-12 shadow-none"
                        color="info"
                        variant="contained"
                        onClick={() => {
                            props.onDismiss()
                        }}
                    >Cancel</Button>

                    <Button
                        className="flex-1 ml-1.5 mr-5 mb-6 rounded-lg h-12 shadow-none"
                        color="secondary"
                        onClick={() => {
                            props.leavingDateSelected(leavingDate)
                            props.onDismiss()
                        }}
                        variant="contained">Confirm</Button>
                </div>

            </Drawer>
        </>
    );
}

export default SelectDateTimeModal;