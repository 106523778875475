import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input-rc-17';
import { AppBarModern } from '../../components/AppBarModern';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { verifyOtp } from '../../services/ApiService';
import { ApiResponse } from '../../models/ApiResponse';
import { SignupResponse } from '../../models/auth/AuthModels';
import { toast } from 'react-toastify';

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  function handleChange(otp) {
    setOtp(otp)
  }

  async function handleVerifyOtp() {
    if (otp.length < 5) {
      toast.warn("Please enter OTP")
      return
    }
    await verifyOtp({
      otp: otp
    })
      .then((response: { data: ApiResponse<SignupResponse> }) => {
        toast.success(response.data.message)
        localStorage.setItem("is_verified", "true")
        navigate("/edit-profile", {
          replace: true
        })
      })
      .catch(function (error) {
        setIsLoaded(false)
        if (error.response)
          toast.error(error.response.data.message + " ")
        else
          toast.error(error.message)
      })
  }

  return (
    <div>
      <Helmet>
        <title>Verify OTP</title>
      </Helmet>
      <AppBarModern title={"Help us verify \nyour account"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-24 mx-5">
        <div>
          An OTP has been sent to <span className="font-medium">{localStorage.getItem("email")}</span>. Please check
          email inbox (also spam folder)
        </div>

        <div className="otp-container flex items-center mb-5">
          <OtpInput
            className="otp-holder mt-5"
            value={otp}
            onChange={handleChange}
            numInputs={5}
          />
        </div>


        {isLoaded ?
          <div className="flex justify-center h-12 mt-7">
            <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
          </div>
          :
          <button
            onClick={handleVerifyOtp}
            className="input-btn mt-5">Verify OTP
          </button>
        }

      </main>
    </div>
  );
}

export default VerifyOtp;