import * as React from 'react';
import ButtonUnstyled, { buttonUnstyledClasses, ButtonUnstyledProps, } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';


const CustomButtonRoot = styled('button')`
  background-color: #000;
  padding: 12px 24px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;

  &:hover {
    background-color: #222;
    border-radius: 0.5rem;
  }
;
}

&.${buttonUnstyledClasses.active} {
  background-color: #333;
  border-radius: 0.5rem;
}

&.${buttonUnstyledClasses.focusVisible} {
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
  outline: none;
  border-radius: 0.5rem;
}

&.${buttonUnstyledClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
  border-radius: 0.5rem;
}
`;

export function InputButton(props: ButtonUnstyledProps) {
  return <ButtonUnstyled
    {...props} component={CustomButtonRoot}
    clickable/>;
}
