import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { FiLock, FiMail } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { AppBarModern } from '../../components/AppBarModern';
import { toast } from 'react-toastify';
import { login, registration } from '../../services/ApiService';
import firebaseApp from '../../services/Firebase';
import { ApiResponse } from '../../models/ApiResponse';
import { SignupResponse } from '../../models/auth/AuthModels';

type SignupForm = {
  email: string;
  password: string;
  confirm_password: string;
}
const Signup = () => {
  const { register, handleSubmit } = useForm<SignupForm>()
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  const onSubmit = handleSubmit(({ email, password, confirm_password }) => {
    let error = ""
    if (password === "")
      error = "Please enter password"
    else if (confirm_password === "")
      error = "Please enter confirm password"
    else if (password !== confirm_password) {
      error = "Confirm password didn't match"
    }

    if (error !== "") {
      toast.warn(error)
      setIsLoaded(false)
    } else {
      setIsLoaded(true)
      handleSignup(email, password)
    }
  });

  async function handleSignup(email: string, password: string) {
    await registration({
      email: email,
      password: password
    })
      .then((response: { data: ApiResponse<SignupResponse> }) => {
        localStorage.setItem("access_token", response.data.data.access_token)
        localStorage.setItem("refresh_token", response.data.data.refresh_token)
        localStorage.setItem("firebase_token", response.data.data.firebase_token)
        localStorage.setItem("id", String(response.data.data.id))
        localStorage.setItem("email", response.data.data.email)
        localStorage.setItem("is_verified", String(response.data.data.is_verified))
        firebaseApp.auth().signInWithCustomToken(response.data.data.firebase_token).then((userCredential) => {
            const user = userCredential.user;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
          });
        toast.success(response.data.message)
        navigate("/verify-otp", {
          replace: true
        })
        console.log(response)
      })
      .catch(function (error) {
        setIsLoaded(false)
        if (error.response) {
          console.log(error);
          toast.error(error.response.data.message + " ")
        } else if (error.request) {
          toast.error(error.message)
          console.log(error.message + " request error");
        } else {
          toast.error(error.message)
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>Signup</title>
      </Helmet>
      <AppBarModern title={"Create new account"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-24 mx-5">
        <div>
          RideMate only allows verified students for safety. Please use your university email.
        </div>
        <form onSubmit={onSubmit} className="loginForm self-center mt-4">

          <div className="relative">
            <FiMail className="absolute left-4 h-12 text-666"/>
            <input
              type="email"
              placeholder="Email Address"
              autoComplete="username"
              className="input-bg pl-11"
              {...register("email", { required: true })}/>
          </div>

          <div className="relative mt-4">
            <FiLock className="absolute left-4 h-12 -mt-px text-666"/>
            <input
              type="password"
              placeholder="Password"
              className="input-bg pl-11"
              id="current-password"
              {...register("password", { required: true })}/>
          </div>

          <div className="relative mt-4">
            <FiLock className="absolute left-4 h-12 -mt-px text-666"/>
            <input
              type="password"
              placeholder="Confirm Password"
              className="input-bg pl-11"
              id="confirm-password"
              {...register("confirm_password", { required: true })}/>
          </div>

          {isLoaded ?
            <div className="flex justify-center h-12 mt-7">
              <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
            </div>
            :
            <button
              className="input-btn mt-5">Signup
            </button>
          }

        </form>
      </main>
    </div>
  );
}

export default Signup;