import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FiLock, FiMail } from "react-icons/fi";
import { useForm } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import firebaseApp from '../../services/Firebase';
import 'firebase/compat/auth';
import { Helmet } from 'react-helmet';
import { login } from '../../services/ApiService';

type LoginForm = {
  email: string;
  password: string;
}

type LoginResponse = {
  message: string;
  data: {
    id: number;
    username: string;
    email: string;
    gender: string;
    picture: string;
    university_id: number;
    university_name: string;
    is_super_user: number;
    is_verified: boolean;
    access_token: string;
    refresh_token: string;
    firebase_token: string;
  }
}

const Login = () => {

  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { register, handleSubmit } = useForm<LoginForm>()

  const onSubmit = handleSubmit(({ email, password }) => {
    setIsLoaded(true)
    loginHandle(email, password).then(r => {
      setIsLoaded(false)
    })
  });

  function showToast() {
    toast.success("yoooo")
  }

  async function loginHandle(email: string, password: string) {
    await login({
      email: email,
      password: password
    })
      .then((response: { data: LoginResponse }) => {
        localStorage.setItem("access_token", response.data.data.access_token)
        localStorage.setItem("refresh_token", response.data.data.refresh_token)
        localStorage.setItem("firebase_token", response.data.data.firebase_token)
        localStorage.setItem("id", String(response.data.data.id))
        localStorage.setItem("username", response.data.data.username)
        localStorage.setItem("email", response.data.data.email)
        localStorage.setItem("gender", response.data.data.gender)
        localStorage.setItem("picture", response.data.data.picture)
        localStorage.setItem("is_verified", String(response.data.data.is_verified))
        localStorage.setItem("university_name", response.data.data.university_name)
        localStorage.setItem("university_id", String(response.data.data.university_id))
        firebaseApp.auth().signInWithCustomToken(response.data.data.firebase_token).then((userCredential) => {
            const user = userCredential.user;
            console.log("logged to firebase")
            console.log(user)
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
          });
        toast.success(response.data.message)
        if (response.data.data.is_verified)
          window.dispatchEvent(new Event('storage'))
        navigate(response.data.data.is_verified ? "/" : "/verify-otp", {
          replace: true
        })
        if (!response.data.data.is_verified)
          window.dispatchEvent(new Event('storage'))
        console.log(response)
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error);
          toast.error(error.response.data.message + " ")
        } else if (error.request) {
          toast.error(error.message)
          console.log(error.message + " request error");
        } else {
          toast.error(error.message)
        }
      })
  }

  return (
    <main>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="self-center max-w-md mx-auto">
        <form onSubmit={onSubmit} className="loginForm m-auto mt-12 mx-8 self-center">
          <div>

            <Link
              to="/"
              className="mx-auto">
              <img
                className="w-52 self-center block mx-auto mb-10"
                src="/images/logo.svg"/>
            </Link>


            <div className="relative">
              <FiMail className="absolute left-4 h-12 text-666"/>
              <input
                type="email"
                placeholder="Email Address"
                autoComplete="username"
                className="input-bg pl-11"
                {...register("email", { required: true })}/>
            </div>

            <div className="relative mt-4">
              <FiLock className="absolute left-4 h-12 -mt-px text-666"/>
              <input
                type="password"
                placeholder="Password"
                className="input-bg pl-11"
                autoComplete="current-password"
                id="current-password"
                {...register("password", { required: true })}/>
            </div>

            <Link
              to="/forgot-password"
              style={{
                display: "block"
              }}
              className="w-full text-right mt-2">Forgot password?</Link>

            {isLoaded ?
              <div className="flex justify-center h-12 mt-6">
                <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
              </div>
              :
              <button
                className="input-btn mt-5">Login
              </button>
            }

            <Link
              to="/signup"
              style={{
                display: "block"
              }}
              className="w-full text-center mt-4">Don't have an account? <b
              className="text-black">Signup</b>
            </Link>

              <div className="text-center text-vsm mt-2 text-666">
                  NSUer(Student Companion) account can be used to login.
              </div>

            <div className="text-center text-vsm mt-7 text-666">By continuing, you agree to RideMate's <br/><Link
              className="text-black" to="/privacy-policy">Privacy Policy</Link> and <Link className="text-black"
                                                                                          to="/terms-and-conditions">Terms
              & Conditions</Link></div>
          </div>
        </form>
      </div>
    </main>
  );
}

export default Login