import React from "react";
import { Helmet } from 'react-helmet';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Android, Apple, ArrowBack } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

const Account = () => {
  let navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <AppBar className="bg-white text-black shadow-none">
        <Toolbar
          color="primary">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate(-1);
            }}
            sx={{ mr: 2 }}
          >
            <ArrowBack/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Account
          </Typography>
        </Toolbar>
      </AppBar>

      <main className="mt-20">

        <img className="w-32 h-32 rounded-full bg-f1f3f4 object-cover mx-auto"
             src={localStorage.getItem("picture") === null || localStorage.getItem("picture") === "0" ? "/images/user_placeholder.svg" : "https://images.ridemate.xyz/profile_picture/" + localStorage.getItem("picture")}/>

        <div className="mx-auto text-2xl font-bold mt-5 text-center">
          {localStorage.getItem("username")}
        </div>


        <div className="w-max-body">

          <ul className="list-disc ml-5 mt-10">
            <li className="mt-2"><Link className="mr-10" to="/edit-profile">Edit Profile</Link></li>
            <li className="mt-2"><Link className="mr-10" to="/landing">How it works?</Link></li>
            <li className="mt-2"><Link className="mr-10" to="/privacy-policy">Privacy Policy</Link></li>
            <li className="mt-2"><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
            <li className="mt-2" onClick={() => {
              localStorage.clear()
              window.location.reload()
            }}>Logout
            </li>
          </ul>

          <div className="space-20"></div>

          <div className="hide-when-installed">
            <div className="flex mt-6 mb-5 flex-row gap-5 items-center">
              <a className="flex-1 md:flex-none" data-applink="market://details?id=club.nsuer.nsuer"
                 href="https://play.google.com/store/apps/details?id=xyz.ridemate" rel="alternate">
                <div
                  className="leading-5 md:leading-none py-3 rounded-lg text-white pl-4 pr-6 bg-[#78C257] flex items-center inline">
                  <Android className="mr-3"/>
                  <div>
                    Download
                    <br/> on <span className="font-medium">Android</span>
                  </div>
                </div>
              </a>
              <Link className="flex-1 md:flex-none" to="/ios-installation">
                <div
                  className="leading-5 md:leading-none py-3 rounded-lg text-white pl-4 pr-6 bg-black flex items-center inline">
                  <Apple className="mr-3"/>
                  <div>
                    Install
                    <br/> on <span className="font-medium">iPhone</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>

      </main>

    </div>
  );
}

export default Account;