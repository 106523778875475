import React from 'react';
import { Chip } from '@mui/material';

export class GoingTo extends React.Component<{ goingTo: string, onClick: () => void, onClick1: () => void }> {
  render() {
    return <>
      <div className="mx-5 mt-4 font-medium text-black text-xl">Where are you going?</div>

      <div className="flex mx-5 mt-2 space-x-2.5 font-medium">
        <Chip
          color={this.props.goingTo === 'home' ? 'secondary' : 'default'}
          label="Home"
          onClick={this.props.onClick}
          clickable/>
        <Chip
          color={this.props.goingTo === 'university' ? 'secondary' : 'default'}
          label="University"
          onClick={this.props.onClick1}
          clickable/>
      </div>
    </>;
  }
}