import axiosInstance from './AxiosInstance';
import {CreateRideRequestBody} from '../models/CreateRideRequestBody';
import {
    EditProfileRequest, ForgotPasswordRequest,
    LoginRequest,
    OtpRequest,
    ResetPasswordRequest,
    SignupRequest, TokenRequest
} from '../models/request/LoginRequest';


export function login(body: LoginRequest) {
    return axiosInstance.post(
        '/api/v1/auth/login',
        body
    );
}

export function registration(body: SignupRequest) {
    return axiosInstance.post(
      '/api/v1/auth/registration',
      body
    );
}


export function syncFcmToken(body: TokenRequest) {
    return axiosInstance.post(
        '/api/v1/auth/sync-fcm-token',
        body
    );
}


export function sendFcm(receiverId: number, message: string) {
    return axiosInstance.post(
        '/api/v1/fcm/message',
        {
            receiverId: receiverId,
            message: message
        }
    );
}
export function sendGroupFcm(rideSlug: String, message: string) {
    return axiosInstance.post(
        '/api/v1/fcm/group-message',
        {
            rideSlug: rideSlug,
            message: message
        }
    );
}

export function uploadPhoto(formData) {
    return axiosInstance.post(
      '/api/v1/auth/upload-photo',
      formData, {
          headers: {
              "Content-Type": "multipart/form-data"
          }
      }
    );
}

export function editProfile(body: EditProfileRequest) {
    return axiosInstance.post(
      '/api/v1/auth/edit-profile',
      body
    );
}

export function resetPassword(body: ResetPasswordRequest) {
    return axiosInstance.post(
      '/api/v1/auth/reset-password',
      body
    );
}

export function verifyOtp(body: OtpRequest) {
    return axiosInstance.post(
      '/api/v1/auth/verify-otp',
      body
    );
}

export function requestPasswordReset(body: ForgotPasswordRequest) {
    return axiosInstance.post(
      '/api/v1/auth/request-password-reset',
      body
    );
}

export async function refreshAccessToken() {
    let response = await axiosInstance.post(
        'api/v1/auth/refresh-token',
        {
            access_token: localStorage.getItem("access_token"),
            refresh_token: localStorage.getItem("refresh_token")
        }
    )

    localStorage.setItem("access_token", response.data.data.access_token)
    localStorage.setItem("refresh_token", response.data.data.refresh_token)
    localStorage.setItem("firebase_token", response.data.data.firebase_token)
    return response.data.data.access_token;
}

export function getUniversities() {
    return axiosInstance.get(
        'api/v1/location/universities?district=1'
    );
}

export function getUpazilas() {
    return axiosInstance.get(
        'api/v1/location/upazilas?district=1'
    );
}

export function goOnline(body: CreateRideRequestBody) {
    return axiosInstance.post(
        '/api/v1/ride/go-online',
        body
    );
}

export function goOffline() {
    return axiosInstance.post(
        '/api/v1/ride/go-offline'
    );
}

export function getRideMates(gender) {
    return axiosInstance.get(
        '/api/v1/ride/mates', {
            params: {
                gender: gender
            }
        }
    );
}

export function getRideStatus() {
    return axiosInstance.get(
      '/api/v1/ride/status', {

      }
    );
}



