import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { WebHeader } from '../../components/WebHeader';

const TermsConditions = () => (
  <div>
    <Helmet>
      <title>Terms and Conditions - RideMate</title>
    </Helmet>


    <WebHeader/>

    <main className="w-max-body flex pt-6 md:pt-14 md:items-center flex-col md:flex-row">

      <div className="fac-container addcontainer">
        <h2 className="text-3xl font-bold mb-5">Terms &amp; Conditions</h2>
        <p className="mt-2">These Terms of Use and User Generated Content(UGC) Terms apply to Hmtsoft, Inc.’s (“RideMate”) use
        and/or distribution of certain user-generated content, including, without limitation, use on RideMate’s
        various websites, social channels, mobile app, and other promotional initiatives (collectively, “Promotional
        Channels”).</p>
        <p className="mt-5">In consideration for RideMate’s use and/or distribution of your content, including but not limited
          to images, text, designs, illustrations, music, lyrics, photographs, videos or other intellectual property
          included thereon (the “User Content”), you hereby acknowledge and agree that once RideMate uses or
          distributes any of the User Content via RideMate’s Promotional Channels, or those of Student
          Companion’s sublicensees and/or brand partners, you shall be bound by these UGC Terms. By agreeing to these
          UGC Terms you are providing RideMate with your permission to use your User Content in accordance with
          these UGC Terms. If you are entering into this Agreement on behalf of an organization, you represent that you
          have the authority to do so.</p>
        <p className="mt-5">RideMate reserves the right to modify these UGC Terms without advance notice by posting a revised
          version of these UGC Terms, which modifications will be effective immediately. Accordingly, you should review
          the UGC Terms on a regular and frequent basis.</p>
        <p className="mt-5">You consent to and acknowledge the use of and processing of any personal data associated with your User
          Content by RideMate or its sublicensees and/or brand partners in accordance with RideMate’s
          Privacy Policies.</p>
        <h2 className="text-xl font-bold mt-6">USER CONTENT LICENSE</h2>
        <p className="mt-2">You shall at all times retain ownership of your User Content. You hereby grant to RideMate and its
          executives, employees, affiliates, brand partners, production partners, social media platforms, contractors,
          successors, legal representatives, assigns, third-party service providers and their respective brand partners,
          marketing or public relations agencies and other affiliates (the “Licensed Parties”) a worldwide, perpetual,
          irrevocable, royalty-free, fully-paid, non-exclusive, transferable, sublicensable right and license to use,
          publish, broadcast, transmit, distribute and re-post (i) your User Content and (ii) your username, name,
          voice, image, likeness or other identifying information (“Name and/or Likeness”) as the same appears in
          connection with your User Content, in any manner to be determined in the Licensed Parties’ sole discretion,
          including but not limited to on webpages and social media pages operated by the Licensed Parties, in
          promotional emails and advertisements, and in any and all other marketing, promotional and advertising
          initiatives, and in any media now or hereafter known. </p>
        <p className="mt-5">The Licensed Parties may use, display, reproduce, distribute, transmit, create derivative works from, combine
          with other materials, alter and/or edit your User Content in any manner in their sole discretion, with no
          obligation to you whatsoever. No use of the User Content or your Name and/or Likeness related to the User
          Content need be submitted to You for any approval for use by the Licensed Parties.</p>
        <p className="mt-5">Notwithstanding the above, the Licensed Parties will have no obligation to make any use of any of the rights
          granted by you. The Licensed Parties may choose to use, use and stop using, reuse or not use your User Content
          or Name and/or Likeness at any time. The Licensed Parties’ use of your User Content or your Name and/or
          Likeness does not imply any endorsement of or any affiliation with you. You hereby waive any right to inspect
          or approve any use of the User Content or Name and/or Likeness by the Licensed Parties as permitted
          hereunder. </p>
        <h2 className="text-xl font-bold mt-4">YOUR REPRESENTATIONS</h2>
        <p  className="mt-2">You represent and warrant that:
        </p><p className="mt-5">(i) you are not a minor or you are the parent/guardian of all minors depicted in your User Content, if
        any.</p>
        <p className="mt-5">(ii) you have the full right, power and authority to grant the rights described in these UGC Terms;</p>
        <p className="mt-5">(iii) you own all rights in and to your User Content and Name and/or Likeness and/or have obtained
          appropriate rights and permissions from any and all other persons and/or entities who own, manage or otherwise
          claim any rights with respect to such User Content and Name and/or Likeness, such that you have all necessary
          licenses, rights, consents and permissions to publish the User Content and Name and/or Likeness and to grant
          the rights granted herein, including permission from all person(s) appearing and/or performing in your User
          Content;</p>
        <p className="mt-5">(iv) the Licensed Parties’ use of your User Content and Name and/or Likeness as described herein will not
          violate the rights of any third party, or any law, rule or regulation, including but not limited to consumer
          protection, copyright, trademark, patent, trade secret, privacy, publicity, moral, proprietary or other rights
          and laws;</p>
        <p className="mt-5">(v) the User Content and Name and/or Likeness is not confidential, libelous, defamatory, obscene,
          pornographic, abusive, indecent, threatening, harassing, hateful, or offensive or otherwise unlawful; and</p>
        <p className="mt-5">You hereby fully release, discharge and agree to hold the Licensed Parties, and any person or entity acting
          on their behalf, harmless from any liability related in any way to the Licensed Parties’ use of your User
          Content and your Name and/or Likeness.</p>
        <p className="mt-5">If requested, you will sign any documentation in such manner and at such location as may be required to
          protect, perfect or enforce any of the rights you have granted to RideMate under these UGC Terms.</p>
        <h2 className="text-xl font-bold mt-6">MISCELLANEOUS</h2>
        <p className="mt-2">You agree that if RideMate does not exercise or enforce any legal right or remedy which is contained
          in these UGC Terms (or which RideMate has the benefit of under any applicable law), this will not be
          taken to be a formal waiver of RideMate’s rights and that those rights or remedies will still be
          available to RideMate.</p>
        <p className="mt-5">If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these UGC
          Terms are invalid, then that provision will be removed without affecting the rest of the UGC Terms. The
          remaining provisions of these UGC Terms will continue to be valid and enforceable.</p>
        <h2 className="text-xl font-bold mt-6">Contact Us</h2>
        <p className="mt-2">If you have any questions about this Privacy Policy, please contact us:</p>
        <ul className="list-disc ml-5">
          <li>By email: hmtamim349(at)gmail.com</li>
          <li>By visiting this page on our website: https://ridemate.xyz</li>
        </ul>

        <br/>
        <br/>
      </div>

    </main>
  </div>
);

export default TermsConditions;