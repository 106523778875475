import React from 'react';

const PickDropMarkerLine = () => (
  <div className="flex flex-col items-center mt-2 ml-5 mt-1.5 mb-12">
    <div className="h-3 w-3 border-4 bg-black rounded-full border-black"/>
    <div className="flex-1 w-px2 bg-black rounded-xl mt-px2 mb-px2"/>
    <img className="h-4 w-4 mb-3" style={{
      filter: 'invert(1)'
    }} src="/images/marker_white.svg"/>
  </div>
);

export default PickDropMarkerLine;