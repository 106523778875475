import React from "react";
import { Helmet } from 'react-helmet';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FiCalendar, FiUsers } from 'react-icons/fi';

const Schedules = () => {
  let navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>Schedules</title>
      </Helmet>
      <AppBar className="bg-white text-black shadow-none">
        <Toolbar
          color="primary">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate(-1);
            }}
            sx={{ mr: 2 }}
          >
            <ArrowBack/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Schedules
          </Typography>
        </Toolbar>
      </AppBar>

      <main className="mt-20">

        <FiCalendar className="mx-auto h-40 w-40 text-gray-300 mt-24"/>

        <div className="text-center h-96 align-middle text-xl mt-5">Schedule system is coming soon</div>

      </main>

    </div>
  );
}

export default Schedules;