import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';

export function AppBarModern(props: {title, onClick: () => void }) {
  return <AppBar className="bg-white text-black shadow-none">
    <Toolbar
      color="primary">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={props.onClick}
        sx={{ mr: 2 }}
      >
        <ArrowBack/>
      </IconButton>
    </Toolbar>
    <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} className="mx-5 mb-1 font-bold">
      {props.title}
    </Typography>
  </AppBar>;
}