import { Link } from 'react-router-dom';
import React from 'react';

export const WebHeader = () => {

  let isLogged = localStorage.getItem("access_token") !== null

  return (
    <header className="md:shadow-sm shadow-eee text-black bg-white bg-opacity-95 sticky top-0 z-50">
      <div className="flex align-middle items-center w-max-body pt-6 pb-6">
        <Link to="/"><img className="h-8 md:h-11" src="/images/logo.svg"/></Link>
        <div className="flex-1"></div>

        {!isLogged &&
            <>
                <Link to="/signup"><span className="mr-3.5 md:mr-5 font-medium">Signup</span></Link>
                <Link to="/login">
                <span className="flex justify-center h-9 md:h-10 text-center
                                items-center text-white bg-secondary
                                rounded-full px-5 md:px-6 font-medium">Login</span>
                </Link>
            </>
        }

      </div>
    </header>
  );
}
