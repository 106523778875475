import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { AddressAutoComplete } from '../home/AddressAutoComplete';
import { Upazila, UpazilaResponse } from '../change-status/ChangeStatusModal';
import { editProfile, getUniversities, uploadPhoto } from '../../services/ApiService';
import { ButtonBase, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { AppBarCustom } from '../../components/AppBarCustom';
import { toast } from 'react-toastify';
import { ApiResponse } from '../../models/ApiResponse';
import { EditProfileResponse } from '../../models/auth/AuthModels';
import { MdEdit } from 'react-icons/md';
import { CreateRideRequestBody } from '../../models/CreateRideRequestBody';
import imageCompression from 'browser-image-compression';

const EditProfile = () => {
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [gender, setGender] = React.useState("male");
  const [options, setOptions] = useState<Upazila[]>([]);
  const loading = open && options.length === 0;
  const [selectedUniversity, setSelectedUniversity] = useState<any>({
    id: localStorage.getItem("university_id"),
    name: localStorage.getItem("university_name")
  });
  const [name, setName] = useState("");

  useEffect(() => {
    let name = localStorage.getItem("username")
    if (name && name !== "")
      setName(name)
    let gender = localStorage.getItem("gender")
    if (gender && gender !== "")
      setGender(gender)
  }, [])

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      await getUniversities()
        .then((response: { data: UpazilaResponse }) => {
          if (active) {
            setOptions(response.data.data);
          }
        });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  async function handleUpdateClick() {
    let error = ""

    if (!name || name.length < 3)
      error = "Please enter your full name";
    else if (!selectedUniversity)
      error = "Please select your university"

    if (error !== "") {
      toast.warn(error)
      return
    }

    setIsLoaded(true)

    await editProfile({
      name: name!!,
      gender: gender,
      university: parseInt(selectedUniversity.id)
    })
      .then((response: { data: ApiResponse<EditProfileResponse> }) => {
        toast.success(response.data.message)
        localStorage.setItem("username", response.data.data.username)
        localStorage.setItem("gender", response.data.data.gender)
        localStorage.setItem("university_name", response.data.data.university_name)
        localStorage.setItem("university_id", String(response.data.data.university_id))
        window.location.href = "/"
      })
      .catch(function (error) {
        setIsLoaded(false)
        if (error.response)
          toast.error(error.response.data.message + " ")
        else
          toast.error(error.message)
      })
  }

  const hiddenFileInput = React.useRef<any>(null);
  const profileImgRef = React.useRef<HTMLImageElement>(null);

  const handleClick = event => {
    if (hiddenFileInput !== null)
      hiddenFileInput.current?.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(fileUploaded);

    reader.onloadend = function (e) {
      if (profileImgRef != null && profileImgRef.current != null)
        if (typeof reader.result === "string") {profileImgRef.current.src = reader.result}
    }.bind(this);


    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 600,
      useWebWorker: true
    }
    try {
       imageCompression(imageFile, options).then((compressedFile : File) =>{

         const formData = new FormData();
         formData.append("file", compressedFile);

         uploadPhoto(formData).then((response: { data: any }) => {
           toast(response.data.message)
           if (response.data.success){
             localStorage.setItem("picture", response.data.data.picture)
           }
         });

      })

    } catch (error) {
      toast.error("Couldn't upload image")
      console.log(error);
    }



  };

  return (
    <div>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <AppBarCustom title={"Edit Profile"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-20 mx-5">
        <div className="loginForm self-center">

          <input
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />

          <div className="relative">

            <img ref={profileImgRef} className="w-28 h-28 rounded-full bg-f1f3f4 object-cover mx-auto mb-4 border-2 border-fafafa"
                 src={localStorage.getItem("picture") === null || localStorage.getItem("picture") === "0" ? "/images/user_placeholder.svg" : "https://images.ridemate.xyz/profile_picture/" + localStorage.getItem("picture")}/>

            <div className="mx-auto inline-block flex justify-center items-center -mt-12">
              <ButtonBase
                onClick={handleClick}
                className="bg-secondary rounded-full p-2 ml-16 shadow-sm">
                <MdEdit className="h-5 w-5 text-white"/>
              </ButtonBase>
            </div>

          </div>
          <div>Name</div>
          <input
            type="text"
            placeholder="Full name"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            className="input-bg pl-4 outline-none mt-1"
          />

          <div className="mt-4">Gender</div>

          <Select
            defaultValue={localStorage.getItem("gender") ? localStorage.getItem("gender")!! : "male"}
            onChange={(event: SelectChangeEvent) => {
              setGender(event.target.value);
            }}
            className="bg-inputBg rounded-lg w-full pr-2 border-none no-border mt-1"
            inputProps={{
              name: 'gender',
            }}>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
          </Select>

          <div className="mt-4">University</div>

          <AddressAutoComplete
            open={open}
            hint="Select University"
            onAddressSelected={(value) => {
              setSelectedUniversity(value)
            }}
            defaultValue={selectedUniversity?.name}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options} loading={loading}
          />

          {isLoaded ?
            <div className="flex justify-center h-12 mt-7">
              <Oval color="#000" secondaryColor="#ddd" strokeWidth={5} height={40} width={40}/>
            </div>
            :
            <button
              onClick={handleUpdateClick}
              className="input-btn mt-5">
              Update
            </button>
          }

        </div>
      </main>

    </div>
  );
}

export default EditProfile;