import React from "react";
import { Helmet } from 'react-helmet';
import { WebHeader } from '../../components/WebHeader';


const IOSInstallation = () => (
  <div>
    <Helmet>
      <title>Install on iPhone(iOS) - RideMate</title>
    </Helmet>

    <WebHeader/>

    <main className="w-max-body flex pt-6 md:pt-14 mb-10 flex-col">

      <div className="text-2xl font-medium mb-3">Install on iPhone (iOS)</div>

      <div>RideMate is not available on Apple <b> App Store</b> yet. But you can install it in <b> only 3
        clicks</b>.<br/> Open <b>Safari</b> and follow these steps.
      </div>

      <div className="flex flex-col md:flex-row">
        <img className="block mx-auto w-72 mt-5" src="/images/screenshots/ios_install_step_1.png"/>
        <img className="block mx-auto w-72 mt-5" src="/images/screenshots/ios_install_step_2.png"/>
        <img className="block mx-auto w-72 mt-5" src="/images/screenshots/ios_install_step_3.png"/>
        <img className="block mx-auto w-72 mt-5" src="/images/screenshots/ios_install_step_4.png"/>
      </div>

    </main>
  </div>

);

export default IOSInstallation;