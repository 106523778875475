import React from 'react'
import {Global} from "@emotion/react";
import {Puller} from "../../components/Puller";
import {RideMateResponse} from "../../models/RideMateResponse";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {Button} from "@mui/material";
import {beforeNow, formatDistance, formatLeavingAt} from "../../utils/Utils";
import {useNavigate} from "react-router-dom";


interface Props {
    show: boolean,
    onDismiss: any,
    mateDetails: RideMateResponse | null,
}

const drawerBleeding = 56;

const MateDetailsModal = (props: Props) => {

    const navigate = useNavigate()
    const item = props.mateDetails

    let pickup = ""
    let dropOff = ""
    let pickupSub = ""
    let dropOffSub = ""
    if (item) {
        if (item.going_home) {
            pickup = item.university_name
            dropOff = item.home_address + ", " + item.home_upazila_name
            pickupSub = item.scheduled_at === null || beforeNow(item.scheduled_at) ? "Leaving now" : "Leaving " + formatLeavingAt(item.scheduled_at)
            dropOffSub = formatDistance(item.home_distance) + " away from your home"
        } else {
            dropOff = item.university_name
            pickup = item.home_address + ", " + item.home_upazila_name
            dropOffSub = ""
            pickupSub = (item.scheduled_at === null || beforeNow(item.scheduled_at) ? "Leaving now" : "Leaving " + formatLeavingAt(item.scheduled_at)) + " • " + (formatDistance(item.home_distance) + " away")
        }
    }

    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        overflow: 'visible',
                    },
                }}
            />


            <SwipeableDrawer
                variant="temporary"
                anchor="bottom"
                open={props.show}
                onClose={() => {
                    props.onDismiss()
                }}
                closeAfterTransition={true}
                onOpen={() => {
                }}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                keepMounted={false}>

                <Puller/>

                <div className="relative">
                    <img className="w-24 h-24 rounded-full bg-f1f3f4 object-cover mx-auto mt-6"
                         src={item?.picture === null || item?.picture === "0" ? "/images/user_placeholder.svg" : "https://images.ridemate.xyz/profile_picture/" + item?.picture}/>
                    {item?.vehicle_owner !== "no" &&
                        <span
                            className="absolute mx-auto text-center -mt-5 left-0 right-0">
                                        <span
                                            className={(item?.vehicle_owner === "bike" ? "bg-pink" : "bg-secondary") + " text-white px-1.5 pt-1 pb-1 text-xxs rounded uppercase font-medium"}>
                                            {item?.vehicle_owner + " Owner"}
                                        </span>
                                    </span>
                    }
                </div>
                <div className="mx-auto text-xl font-bold mt-4 text-center">
                    {item?.name}
                </div>

                {item?.verified_university &&
                    <div className="text-center flex mx-auto text-black mt-px2">
                        <img src="/images/shield.svg" className="mr-1"/> From {item?.university_name}
                    </div>
                }


                <div id="locationContainer" className="flex items-center mt-2.5 items-stretch mx-5 mt-px16">
                    <div className="flex flex-col items-center mt-1.5">
                        <div className="h-3 w-3 border-4 bg-[#5E87E2] rounded-full border-[#5E87E2]"/>
                        <div className="flex-1 w-px2.5 bg-eee rounded-xl mt-px2 mb-px2"/>
                        <img className="h-4 w-4 mb-9" src="/images/marker_green.svg"/>
                        <div className="h-px"></div>
                    </div>

                    <div className="flex-1 ml-3 flex flex-col">
                        <span className="text-sm font-medium">Pickup</span>
                        <div className="leading-3 mt-1 text-black text-mmd">{pickup}</div>
                        <div className="leading-3 mt-2 text-sm text-online">{pickupSub}</div>

                        <span className="text-sm font-medium mt-4">Drop Off</span>
                        <div className="leading-3 mt-1 text-black text-mmd">{dropOff}</div>
                        <div className="text-sm leading-3 mt-2 text-online">{dropOffSub}</div>
                    </div>

                </div>


                <div className="flex flex-row mt-8">
                    <Button
                        className="flex-1 ml-5 mr-1.5 mb-6 text-black rounded-lg h-12 shadow-none"
                        color="info"
                        variant="contained"
                        onClick={() => {
                            props.onDismiss()
                        }}
                    >Close</Button>

                    <Button
                        className="flex-1 ml-1.5 mr-5 mb-6 rounded-lg h-12 shadow-none"
                        color="secondary"
                        onClick={() => {
                            props.onDismiss()
                            navigate('/chat', {state: {uid: item?.uid, name: item?.name, picture: item?.picture}})
                        }}
                        variant="contained">Message</Button>
                </div>

            </SwipeableDrawer>
        </>
    )
};

export default MateDetailsModal