import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import AppRoutes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {BottomNav} from './components/BottomNav';
import {createTheme} from '@mui/material';
import {ThemeProvider} from '@emotion/react';
import moment from "moment/moment";

import {getFcmToken} from './services/Firebase';
import {syncFcmToken} from "./services/ApiService";

function App() {

    const [showBottomNav, setShowBottomNav] = useState(localStorage.getItem("access_token") !== null)

    const handleNotificationPermission = async () => {
        const ua = navigator.userAgent || navigator.vendor;
        if ((ua.indexOf("FBAN") > -1) && (ua.indexOf("FBAV") > -1)) {

        } else {
            try {
                console.log("asking for notification permission")
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    getFcmToken.then((token: string) => { // Explicitly define the return type as string
                        if (localStorage.getItem("access_token") !== null && localStorage.getItem("fcm_token") !== token) {
                            syncFcmToken({token: token}).then((response: any) => {
                                localStorage.setItem("fcm_token", token)
                                console.log("fcm token successfully synced")
                            });
                        } else {
                            console.log("fcm token already synced")
                        }
                    });
                } else {
                    console.warn('Notification permission denied.');
                }
            } catch (error) {
                console.error('Error requesting permission:', error);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('storage', () => {
            setShowBottomNav(localStorage.getItem("access_token") !== null)
            if (localStorage.getItem("access_token") !== null)
                handleNotificationPermission().then(r => {
                });
        })

    }, [])


    handleNotificationPermission().then(r => {
    });

    const theme = createTheme({
        palette: {
            secondary: {
                main: "#000",
                contrastText: "#fff"
            },
            primary: {
                main: "#523BE3",
                contrastText: "#fff",
            },
            info: {
                main: "#eee",
                contrastText: "#fff",
            }
        },
        components: {
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        height: '3rem',
                    },
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: '3rem',
                    },
                }
            },
        }
    });

    moment.updateLocale('en', {
        relativeTime: {
            future: "%s",
            past: "%s ago",
            s: ' Just now',
            ss: '%d secs',
            m: "1 min",
            mm: "%d mins",
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            w: "a week",
            ww: "%d weeks",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ToastContainer
                    className="z-[99999]"
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <AppRoutes/>

                {showBottomNav && <BottomNav/>}

            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
