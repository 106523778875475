import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";


const AndroidInstallation = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            navigate("/")
        } else {
            window.location.href= "market://details?id=xyz.ridemate";
        }
    })

    return (
        <div>
            Opening Playstore
        </div>

    );
}

export default AndroidInstallation;