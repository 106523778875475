import React, { useEffect, useRef, useState } from "react";
import Switch from 'react-ios-switch';
import ProgressBar from '@ramonak/react-progress-bar';
import { BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { ButtonBase, Chip } from '@mui/material';
import ChangeStatusModal from '../change-status/ChangeStatusModal';
import { getRideMates, getRideStatus, goOffline, goOnline } from '../../services/ApiService';
import { RideMateResponse } from '../../models/RideMateResponse';
import { ApiResponse } from '../../models/ApiResponse';
import { RideMateList, RideMateListShimmer } from "./RideMateList";
import { Link, useNavigate } from 'react-router-dom';
import { delay, formatLeavingAt } from "../../utils/Utils";
import MateDetailsModal from "../mate-details/MateDetailsModal";
import { CreateRideRequestBody } from '../../models/CreateRideRequestBody';
import Countdown from 'react-countdown';
import { MAX_WAIT_MS } from '../../utils/Constants';


const Home = () => {

  let countDownCompleted = false
  const navigate = useNavigate()
  const uid = localStorage.getItem("id")
  const sheetRef = useRef<BottomSheetRef>(null)
  const [online, setOnline] = useState(localStorage.getItem("is_ride_online") === "true");
  const [gender, setGender] = useState("all");
  const [countDownProgress, setCountDownProgress] = useState(0);
  const [scheduleDelayedTime, setScheduleDelayedTime] = useState(0);
  const [showChangeStatusModal, setChangeStatusModal] = useState(false);
  const [renderChangeStatusModal, setRenderChangeStatusModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mateList, setMateList] = useState<RideMateResponse[]>()
  const [rideDetails, setRideDetails] = useState<RideMateResponse>()
  const [goingTo, setGoingTo] = useState(localStorage.getItem("going_to") || "home");
  const [showBoxAnimation, setShowBoxAnimation] = useState<boolean>(false);
  const [selectedMateDetails, setSelectedMateDetails] = useState<RideMateResponse | null>(null);
  const [showMateDetails, setShowMateDetails] = useState(false);

  useEffect(() => {
    loadRideMates()
    let rideDetailsString = localStorage.getItem("ride_details");
    if (rideDetailsString) {
      try {
        let rideDetailsJson = JSON.parse(rideDetailsString);
        setRideDetails(rideDetailsJson)
      } catch (e) {
      }
    }

    loadRideStatus(false)
    updateRideCard()
  }, [])

  useEffect(() => {
    if (rideDetails?.scheduled_at !== null) {
      setScheduleDelayedTime(new Date(rideDetails?.scheduled_at).getTime() + MAX_WAIT_MS)
    }
  }, [rideDetails])

  useEffect(() => {
    loadRideMates()
  }, [gender])

  function handleGoingTo() {
    let goingToVal = goingTo === "home" ? "university" : "home"
    localStorage.setItem("going_to", goingToVal)
    setGoingTo(goingToVal)
    // setShowBoxAnimation(true);
    // (async () => {
    //     await delay(500)
    //     setShowBoxAnimation(false)
    // })();
    findRideMates()
  }

  function findRideMates() {
    const rideDetails = localStorage.getItem("ride_details")
    if (rideDetails !== null) {
      const rideJson = JSON.parse(rideDetails)
      if (rideJson !== null) {

        let nowDate = new Date()
        let scheduledAt = new Date(rideJson.scheduled_at)
        if (nowDate > scheduledAt) {
          scheduledAt = nowDate
        }

        goOnline({
          university_id: rideJson.university_id,
          home_upazila_id: rideJson.home_upazila_id,
          home_address: rideJson.home_address,
          home_lat: rideJson.home_lat,
          home_lng: rideJson.home_lng,
          scheduled_at: scheduledAt.toISOString(),
          vehicle_owner: rideJson.vehicle_owner,
          going_home: goingTo !== "home"
        }).then((response: { data: CreateRideRequestBody }) => {
          localStorage.setItem("is_ride_online", "true")
          localStorage.setItem("ride_slug", goingTo === "home" ? "H" + rideJson.home_upazila_id + "_U" + rideJson.university_id : "U" + rideJson.university_id + "_H" + rideJson.home_upazila_id)
          toast.success("You are online now")
          loadRideStatus(true)
          loadRideMates()
        });
      }
    }
  }


  function loadRideStatus(showAnimation) {
    getRideStatus().then((response: { data: ApiResponse<RideMateResponse> }) => {
      let item = response.data.data
      const homeUpazilaId = item.home_upazila_id
      const universityId = item.university_id
      setRideDetails(item)
      let goingToVal = item.going_home ? "home" : "university"
      setGoingTo(goingToVal)

      localStorage.setItem("ride_details", JSON.stringify(item))
      localStorage.setItem("user_home", JSON.stringify(
        {
          upazila_id: item.home_upazila_id,
          upazila_name: item.home_upazila_name,
          address_line: item.home_address,
          map_used: true,
          lat: item.home_lat,
          lng: item.home_lng
        }
      ))

      localStorage.setItem("user_university", JSON.stringify({
        id: item.university_id,
        name: item.university_name
      }))

      localStorage.setItem("is_ride_online", item.online ? "true" : "false")
      localStorage.setItem("ride_slug", goingTo === "home" ? "H" + homeUpazilaId + "_U" + universityId : "U" + universityId + "_H" + homeUpazilaId)
      setOnline(item.online)
      updateRideCard()

      if (showAnimation) {
        setShowBoxAnimation(true);
        (async () => {
          await delay(500)
          setShowBoxAnimation(false)
        })();
      }
    });
  }

  function updateRideCard() {

  }

  function loadRideMates() {
    setLoading(true)
    getRideMates(gender).then((response: { data: ApiResponse<RideMateResponse[]> }) => {
      setLoading(false)
      if (response.data.data === undefined) {
        setMateList([])
      }
      else
        setMateList(response.data.data)
    })
  }


  function changeStatus(checked: boolean | ((prevState: boolean) => boolean)) {
    if (checked) {
      setRenderChangeStatusModal(true)
      setChangeStatusModal(true)
    } else {
      goOffline().then((response: { data: any }) => {
        localStorage.setItem("is_ride_online", "false")
        toast.warn("You are offline now")
        setOnline(false)
      });
    }
  }

  function onChangeStatusModalDismiss() {
    setChangeStatusModal(false);
    (async () => {
      await delay(500)
      setRenderChangeStatusModal(false)
    })();

    loadRideStatus(false)
    loadRideMates()
  }

  function getHomeAddress() {
    if (rideDetails?.home_address && rideDetails?.home_upazila_name)
      return rideDetails?.home_address + ", " + rideDetails?.home_upazila_name
    else if (rideDetails?.home_address)
      return rideDetails?.home_address
    else
      return "Home address is not set"
  }

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if (!countDownCompleted)
        loadRideStatus(false)
      countDownCompleted = true
      return <span> You are offline now </span>;
    } else {
      countDownCompleted = false
      let diff = scheduleDelayedTime - new Date().getTime();
      setCountDownProgress(((diff * 100) / MAX_WAIT_MS) | 0)

      let scheduleDiffTime = new Date().getTime() - new Date(rideDetails?.scheduled_at).getTime()

      if (hours > 2 || scheduleDiffTime < 0)
        return "Leaving " + formatLeavingAt(rideDetails?.scheduled_at)

      let text;
      if (hours > 0)
        text = appendS(hours, "hour") + " " + appendS(minutes, "minute")
      else if (minutes > 0)
        text = appendS(minutes, "minute")
      else
        text = appendS(seconds, "second")
      return <span>Going offline in {text}</span>
    }
  };

  function appendS(val, str) {
    if (val === 1)
      return val + " " + str;
    else
      return val + " " + str + "s";
  }

  return (
    <main>
      <Helmet>
        <title>RideMate - Split Fare, Save Money!</title>
      </Helmet>
      <div className="self-center max-w-md mx-auto h-fit mb-20">

        <div className="mx-4">
          <header className="flex pt-4 pb-4 items-center sticky top-0 bg-white z-50">
            <div className="flex-1">
              <img className="h-8" src="/images/logo.svg"/>
            </div>
            <Link to="/account"><img className="h-9 w-9" src="/images/user_placeholder.svg"/></Link>
          </header>

          <div
            className={"rounded-xl px-5 pt-4 pb-3.5 text-white " + (online ? "bg-secondary" : "bg-offline")}>
            <div className="flex">
              <div
                className="flex-1 font-medium text-xl">{online ? "I'm looking for a ride" : "Hey! Need a ride?"}</div>
              <Switch
                onColor="#6875f0"
                offColor="#666"
                checked={online}
                onChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                  changeStatus(checked)
                }}/>
            </div>

            <div id="locationContainer" className="flex items-center mt-2.5 items-stretch">

              <div className="flex flex-col items-center mt-1.5">
                <div className="h-3 w-3 border-4 bg-white rounded-full border-white"/>
                <div className="flex-1 w-px2 bg-white rounded-xl mt-px2 mb-px2"/>
                <img className="h-4 w-4 mb-3.5" src="/images/marker_white.svg"/>
              </div>

              <div className="flex-1 ml-3 flex flex-col">
                <span className="text-sm order-1">Pickup Location</span>
                <div
                  className={(goingTo === "home" ? 'order-2' : 'order-5') + ' ' + (showBoxAnimation ? goingTo === "home" ? ' animate-bounce3-fast' : ' animate-bounce-fast' : '')}
                  style={{
                    transition: "flex-grow 200ms linear",
                  }}>
                  <div
                    className="text-md leading-3 mt-px order-2">{rideDetails?.university_name || localStorage.getItem("university_name")}</div>
                </div>
                <div className="mt-4 order-3"></div>

                <span className="text-sm order-4">Drop Off Location</span>
                <div
                  className={(goingTo === "home" ? 'order-5' : 'order-2') + ' ' + (showBoxAnimation ? goingTo === "home" ? ' animate-bounce-fast' : ' animate-bounce3-fast' : '')}
                  style={{
                    transition: "flex-grow 200ms linear"
                  }}>
                  <div
                    className="text-md leading-3 mt-px">{getHomeAddress()}</div>
                </div>

              </div>

              <div className="flex items-center">
                <ButtonBase
                  onClick={() => handleGoingTo()}
                  className="bg-[#ffffff2e] rounded-full inline-block h-12 w-12 flex justify-center items-center">
                  <img src="/images/up-down-arrow.png" className="h-6 w-6"/>
                </ButtonBase>
              </div>

            </div>

            {online && scheduleDelayedTime > 0
              ? <div>
                <ProgressBar className="mt-6 h-2" height="6px" baseBgColor="#0000002e" bgColor="#fff"
                             completed={countDownProgress}
                             isLabelVisible={false}/>
                <div className="text-center text-sm mt-2.5">
                  {scheduleDelayedTime > new Date().getTime() ?
                    <Countdown
                      renderer={countdownRenderer}
                      date={scheduleDelayedTime}/>
                    :
                    <span>You are offline now</span>
                  }
                </div>
              </div>
              : <div className="h-5"/>}
          </div>
        </div>

        <div className="mx-5 mt-4 font-medium text-black text-xl">Available ride mates</div>

        <div className="flex mx-4 mt-2 space-x-2.5 font-medium">
          <Chip
            color={gender === 'all' ? 'secondary' : 'default'}
            label="ALL"
            onClick={() => setGender("all")}
            clickable/>
          <Chip
            color={gender === 'male' ? 'secondary' : 'default'}
            label="MALE"
            onClick={() => setGender("male")}
            clickable/>
          <Chip
            color={gender === 'female' ? 'secondary' : 'default'}
            label="FEMALE"
            onClick={() => setGender("female")}
            clickable/>
        </div>

        <div className="mx-4 mt-2 pt-3">
          {loading ?
            [...Array(5)].map((_, i) =>
              <RideMateListShimmer/>)
            : mateList?.map((item) =>
              <RideMateList onItemClick={(model) => {
                setShowMateDetails(true);
                (async () => {
                  await delay(100)
                  setSelectedMateDetails(model)
                })();
              }} item={item}/>
            )}
        </div>

        {!loading && mateList?.length === 0 &&
            <div className="text-center">

                <img className="mx-auto px-24 mt-8 w-auto" src={"/images/ic_no_ridemate.svg"}/>
                <div className="text-lg mt-6 px-10 text-black">No ride mates found :(<br/>Please check again after
                    sometime.
                </div>

            </div>
        }

        {showMateDetails &&
            <MateDetailsModal
                show={selectedMateDetails !== null}
                onDismiss={() => {
                  setSelectedMateDetails(null);
                  (async () => {
                    await delay(500)
                    setShowMateDetails(false)
                  })();
                }}
                mateDetails={selectedMateDetails}/>
        }


        {renderChangeStatusModal &&
            <ChangeStatusModal onSetOnline={() => setOnline(true)} onDismiss={onChangeStatusModalDismiss}
                               show={showChangeStatusModal}/>
        }

      </div>
    </main>
  );
}

export default Home;