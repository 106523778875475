import React, {useEffect, useRef, useState} from "react";
import {BottomSheetRef} from 'react-spring-bottom-sheet';
import {Button, Chip, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Global} from '@emotion/react';
import {Puller} from '../../components/Puller';
import {InputButton} from '../../components/InputButton';
import PickDropMarkerLine from '../home/PickDropMarkerLine';
import {GoingTo} from '../home/GoingTo';
import SelectDateTimeModal from './SelectDateTimeModal';
import moment from 'moment/moment';
import {ArrowRight} from '@mui/icons-material';
import {AddressAutoComplete} from '../home/AddressAutoComplete';
import HomeAddressModal from '../home/HomeAddressModal';
import {delay} from '../../utils/Utils';
import {getUniversities, goOnline} from '../../services/ApiService';
import {CreateRideRequestBody} from '../../models/CreateRideRequestBody';
import {toast} from 'react-toastify';


const drawerBleeding = 56;

interface Props {
    show: boolean,
    onSetOnline: any,
    onDismiss: any,
}

export type UpazilaResponse = {
    message: string;
    data: [
        Upazila
    ]
}

export type Upazila = {
    id: number;
    district_id: number;
    name: string;
    lat: number;
    lng: number;
}


const ChangeStatusModal = (props: Props) => {
    const [open, setOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [addressModalOpen, setAddressModalOpen] = React.useState(false);
    const [dateTimeModalOpen, setDateTimeModalOpen] = React.useState(false);
    const [showBoxAnimation, setShowBoxAnimation] = useState<boolean>(false);
    const [options, setOptions] = useState<Upazila[]>([]);
    const [goingTo, setGoingTo] = useState(localStorage.getItem("going_to") || "home");
    const [hasVehicle, setHasVehicle] = useState("no");
    const [leavingAt, setLeavingAt] = useState("1");
    const [leavingDate, setLeavingDate] = useState(new Date());
    const [selectedUniversity, setSelectedUniversity] = useState<any>();
    const [selectedAddress, setSelectedAddress] = useState<any>();
    const loading = open && options.length === 0;

    const handleLeavingAtChange = (event: SelectChangeEvent) => {
        if (event.target.value.toString() === "2") {
            setDateTimeModalOpen(true);
        } else {
            setLeavingAt(event.target.value as string);
        }
    }

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    }

    function syncUserAddressFromLocalStorage() {
        const userAddress = localStorage.getItem("user_home");
        if (userAddress != null) {
            const json = JSON.parse(userAddress)
            if (json != null) {
                setSelectedAddress(json)
            }
        }
    }

    useEffect(() => {
        const userUniversity = localStorage.getItem("user_university");
        if (userUniversity != null) {
            const json = JSON.parse(userUniversity)
            if (json != null) {
                setSelectedUniversity(json)
            }
        }
        syncUserAddressFromLocalStorage()

        setGoingTo(localStorage.getItem("going_to") || "home")
    }, [])

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }

        (async () => {
            await getUniversities()
                .then((response: { data: UpazilaResponse }) => {
                    if (active) {
                        setOptions(response.data.data);
                    }
                });
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    function handleGoingTo(goingTo: string) {
        localStorage.setItem("going_to", goingTo)
        setGoingTo(goingTo)
        setShowBoxAnimation(true);
        (async () => {
            await delay(500)
            setShowBoxAnimation(false)
        })();
    }

    function handleFindRideMatesClick() {
        const homeUpazilaId = selectedAddress.upazila_id
        const universityId = selectedUniversity.id
        goOnline({
            university_id: universityId,
            home_upazila_id: homeUpazilaId,
            home_address: selectedAddress.address_line,
            home_lat: selectedAddress.lat,
            home_lng: selectedAddress.lng,
            scheduled_at: leavingDate.toISOString(),
            vehicle_owner: hasVehicle,
            going_home: goingTo === "home"
        }).then((response: { data: CreateRideRequestBody }) => {
            localStorage.setItem("is_ride_online", "true")
            localStorage.setItem("ride_slug", goingTo === "home" ? "H" + homeUpazilaId + "_U" + universityId : "U" + universityId + "_H" + homeUpazilaId)
            toast.success("You are online now")
            props.onSetOnline(true)
            props.onDismiss()
        });
    }

    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        overflow: 'visible',
                    },
                }}
            />

            <SwipeableDrawer
                variant="temporary"
                anchor="bottom"
                open={props.show}
                onClose={() => {
                    props.onDismiss()
                }}
                closeAfterTransition={true}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                keepMounted={false}>

                <Puller/>

                <GoingTo
                    goingTo={goingTo}
                    onClick={() => {
                        handleGoingTo("home")
                    }}
                    onClick1={() => {
                        handleGoingTo("university")
                    }}
                />

                <div className="flex items-center mt-4 items-stretch">

                    <PickDropMarkerLine/>
                    <div
                        className="flex-1 ml-2 mr-5 flex flex-col"
                        style={{
                            transition: "flex-grow 200ms linear"
                        }}>

                        <div className="font-medium text-black order-1">Pickup Location</div>
                        <div
                            className={(goingTo === "home" ? 'order-2' : 'order-4') + ' ' + (showBoxAnimation ? goingTo === "home" ? ' animate-bounce3-fast' : ' animate-bounce-fast' : '')}
                            style={{
                                transition: "flex-grow 200ms linear",
                            }}>
                            <AddressAutoComplete
                                open={open}
                                hint="Select University"
                                onAddressSelected={(value) => {
                                    localStorage.setItem("user_university", JSON.stringify(value))
                                    setSelectedUniversity(value)
                                }}
                                defaultValue={selectedUniversity?.name}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                options={options} loading={loading}
                            />
                        </div>

                        <div className="font-medium text-black mt-3 order-3 ">Drop Off Location</div>

                        <div
                            className={(goingTo === "home" ? 'order-4' : 'order-2') + ' ' + (showBoxAnimation ? goingTo === "home" ? ' animate-bounce-fast' : ' animate-bounce3-fast' : '')}
                            style={{
                                transition: "flex-grow 200ms linear"
                            }}>
                            <div className="w-auto mt-1 flex mb-1">
                                <Button
                                    color="secondary"
                                    onClick={() => setAddressModalOpen(true)}
                                    className="bg-inputBg rounded-lg w-full block h-12 flex items-center px-[12px] justify-start normal-case font-normal text-[16px]">
                                    {(selectedAddress?.address_line) ? selectedAddress.address_line + ", " + selectedAddress.upazila_name : "Select Home"}
                                    <div className="flex-1"/>
                                    <ArrowRight fontSize="medium" className="text-777 -mr-1"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {addressModalOpen &&
                    <HomeAddressModal
                        show={addressModalOpen}
                        onDismiss={() => {
                            syncUserAddressFromLocalStorage()
                            setAddressModalOpen(false)
                        }}/>
                }

                <div className="flex items-center mt-3 items-stretch">
                    <div className="flex flex-col items-center mt-1 ml-5 mt-1.5 mb-12">
                        <img className="h-4 w-4 mb-3.5" src="/images/clock_black.svg"/>
                    </div>

                    <div className="flex-1 ml-2 mr-5">
                        <div className="font-medium text-black">When are you leaving?</div>
                        <div className="w-auto mt-1 input-holder">
                            <Select
                                labelId="demo-simple-select-label"
                                className="bg-inputBg rounded-lg"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={leavingAt}
                                onChange={handleLeavingAtChange}
                            >
                                <MenuItem value={1}>Right now</MenuItem>
                                <MenuItem value={2}>Set date and time</MenuItem>
                                {leavingAt !== "1" &&
                                    <MenuItem value={3}>{moment(leavingDate).format('hh:mm A, DD MMM yyyy')}</MenuItem>
                                }
                            </Select>
                        </div>
                    </div>

                </div>


                <div className="mx-5 mt-4 font-medium text-black">Do you own a vehicle and share ride?</div>

                <div className="flex mx-5 mt-2 space-x-2.5 font-medium">
                    <Chip
                        color={hasVehicle === 'no' ? 'secondary' : 'default'}
                        onClick={() => setHasVehicle("no")}
                        label="No"/>
                    <Chip
                        color={hasVehicle === 'car' ? 'secondary' : 'default'}
                        onClick={() => setHasVehicle("car")}
                        label="Car"/>
                    <Chip
                        color={hasVehicle === 'bike' ? 'secondary' : 'default'}
                        onClick={() => setHasVehicle("bike")}
                        label="Bike"/>
                </div>

                <InputButton onClick={handleFindRideMatesClick} className="mt-6 mx-5 mb-6">Find ride mates</InputButton>

            </SwipeableDrawer>

            {dateTimeModalOpen &&
                <SelectDateTimeModal
                    onDismiss={() => setDateTimeModalOpen(false)}
                    show={dateTimeModalOpen}
                    leavingDateSelected={(date: Date) => {
                        setLeavingDate(date)
                        setLeavingAt("3")
                    }}
                />
            }
        </>
    );
}

export default ChangeStatusModal;