import {Badge, BottomNavigation, BottomNavigationAction, Paper} from '@mui/material';
import {FiHome, FiInbox, FiMessageSquare, FiUser} from 'react-icons/fi';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import firebaseApp from "../services/Firebase";
import {useList} from "react-firebase-hooks/database";

export const BottomNav = () => {

    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);
    const db = firebaseApp.database()
    const uid = localStorage.getItem("id")
    const [snapshots, chatLoading, chatError] = useList(db.ref("chat_personal/chats/" + uid).orderByChild("seen").equalTo(false).limitToLast(100));

    useEffect(() => {
        if (firebaseApp.auth().currentUser == null)
            firebaseApp.auth()
                .signInWithCustomToken(localStorage.getItem("firebase_token") || "")
                .then((userCredential) => {
                })
    }, [])

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 40}} elevation={3}>
            <BottomNavigation
                className=""
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    // if (newValue === 0){
                    //     navigate("/", {
                    //         replace: true
                    //     })
                    //     window.location.replace("/")
                    // }
                }}
                sx={{
                    height: 66,
                }}
            >
                <BottomNavigationAction label="Home" icon={<FiHome/>} component={Link} to="/"/>
                <BottomNavigationAction label="Inbox" icon={<Badge color="secondary"
                                                                   badgeContent={snapshots?.length}><FiInbox/></Badge>}
                                        component={Link} to="/messages"/>
                <BottomNavigationAction label="Chatroom" icon={<FiMessageSquare/>} component={Link} to="/chatroom"/>
                <BottomNavigationAction label="Account" icon={<FiUser/>} component={Link} to="/account"/>
            </BottomNavigation>
        </Paper>
    )
}