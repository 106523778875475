import React from "react";
import { Helmet } from 'react-helmet';
import { WebHeader } from '../../components/WebHeader';


const PrivacyPolicy = () => (
  <div>
    <Helmet>
      <title>Privacy Policy - RideMate</title>
    </Helmet>

    <WebHeader/>

    <main className="w-max-body flex pt-6 md:pt-14 md:items-center flex-col md:flex-row">

      <div className="fac-container addcontainer">
        <h2 className="text-4xl font-bold">Privacy Policy</h2>
        <p className="mt-5">Hmtsoft, Inc. ("us", "we", or "our") operates the <b>RideMate</b> application (the
        "Service").</p>
        <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you
          use our Service and the choices you have associated with that data.</p>
        <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and
          use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used
          in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
        <h2>Information Collection And Use</h2>
        <p>We collect several different types of information for various purposes to provide and improve our Service to
          you.</p>
        <h3 className="text-2xl font-bold mt-4">Types of Data Collected</h3>
        <h4 className="text-xl font-bold mt-4">Personal Data</h4>
        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that
          can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but
          is not limited to:</p>
        <ul className="list-disc ml-5">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Gender</li>
          <li>University</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <h4 className="text-xl font-bold mt-4">Usage Data</h4>
        <p>When you access the Service by or through a mobile device, we will not collect any personal information
          automatically or in background. During sign up, you give those information to create a new acccount. For a
          better experience, while using our Service, we may require you to provide us with certain personally
          identifiable information. The information that we request will be retained by us and used as described in this
          privacy policy.
          The app does use third party services that may collect information used to identify you.</p>
        <h4 className="text-xl font-bold mt-4">Tracking &amp; Cookies Data</h4>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain
          information.</p>
        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are
          sent to your browser from a website and stored on your device. Tracking technologies also used are beacons,
          tags, and scripts to collect and track information and to improve and analyze our Service.</p>
        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
          you do not accept cookies, you may not be able to use some portions of our Service.</p>
        <p>Examples of Cookies we use:</p>
        <ul className="list-disc ml-5">
          <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
          <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various
            settings.
          </li>
          <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
        </ul>
        <h2 className="text-xl font-bold mt-4">Use of Data</h2>
        <p>Hmtsoft, Inc. uses the collected data for various purposes:</p>
        <ul className="list-disc ml-5">
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer care and support</li>
          <li>To provide analysis or valuable information so that we can improve the Service</li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <h2 className="text-xl font-bold mt-4">Transfer Of Data</h2>
        <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located
          outside of your state, province, country or other governmental jurisdiction where the data protection laws may
          differ than those from your jurisdiction.</p>
        <p>If you are located outside Bangladesh and choose to provide information to us, please note that we transfer
          the data, including Personal Data, to Bangladesh and process it there.</p>
        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement
          to that transfer.</p>
        <p>Hmtsoft, Inc. will take all steps reasonably necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization
          or a country unless there are adequate controls in place including the security of your data and other
          personal information.</p>
        <h2 className="text-2xl font-bold mt-4">Disclosure Of Data</h2>
        <h3 className="text-xl font-bold mt-4">Legal Requirements</h3>
        <p>Hmtsoft, Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul className="list-disc ml-5">
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Hmtsoft, Inc.</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>
        <h2 className="text-xl font-bold mt-4">Security Of Data</h2>
        <p>The security of your data is important to us, but remember that no method of transmission over the Internet,
          or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
          protect your Personal Data, we cannot guarantee its absolute security.</p>
        <h2 className="text-xl font-bold mt-4">Service Providers</h2>
        <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to
          provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our
          Service is used.</p>
        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are
          obligated not to disclose or use it for any other purpose.</p>
        <h3 className="text-xl font-bold mt-4">Analytics</h3>
        <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
        <ul className="list-disc ml-5">
          <li>
            <p><strong>Firebase</strong></p>
            <p>Firebase is analytics service provided by Google Inc.</p>
            <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device
              advertising settings or by following the instructions provided by Google in their Privacy Policy: <a
                href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
            <p>We also encourage you to review the Google's policy for safeguarding your data: <a
              href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.
              For more information on what type of information Firebase collects, please visit please visit the Google
              Privacy &amp; Terms web page: <a
                href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
          </li>
        </ul>
        <h2 className="text-xl font-bold mt-4">Links To Other Sites</h2>
        <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link,
          you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
          site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.</p>
        <h2 className="text-xl font-bold mt-4">Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
        <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a
          parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us.
          If we become aware that we have collected Personal Data from children without verification of parental
          consent, we take steps to remove that information from our servers.</p>
        <h2 className="text-xl font-bold mt-4">Changes To This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page.</p>
        <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
          effective and update the "effective date" at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.</p>
        <h2 className="text-xl font-bold mt-4">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul className="list-disc ml-5">
          <li>By email: hmtamim349(at)gmail.com</li>
          <li>By visiting this page on our website: https://ridemate.xyz</li>
        </ul>
        <br/>
        <br/>
      </div>
    </main>
  </div>

);

export default PrivacyPolicy;