import axios from 'axios';
import { refreshAccessToken } from './ApiService';

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? `http://localhost:3000/` : `https://api.ridemate.xyz/`,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers = config.headers || {};
  config.headers.common['Authorization'] = "Bearer " + token;
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {

  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let access_token = await refreshAccessToken();
    error.response.config.headers['Authorization'] = 'Bearer ' + access_token;
    return axiosInstance(originalRequest);
  } else if (error.response.status === 403) {
    localStorage.removeItem("access_token");
    window.location.href = '/login'
  }

  return Promise.reject(error);
});

export default axiosInstance;