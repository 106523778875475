import { Autocomplete, CircularProgress } from '@mui/material';
import { EditText } from '../../components/EditText';
import React from 'react';

export function AddressAutoComplete(props: { open: boolean, onOpen: () => void, onClose: () => void, onAddressSelected: (value) => void, options: any[], loading: boolean, defaultValue?: string, hint: string }) {
  return <Autocomplete
    className="w-auto -mt-1"
    open={props.open}
    onOpen={props.onOpen}
    onClose={props.onClose}
    isOptionEqualToValue={(option, value) => option.name === value.name}
    getOptionLabel={(option) => option.name}
    options={props.options}
    loading={props.loading}
    {...((props.defaultValue && { defaultValue: { name: props.defaultValue } }))}
    onChange={(event, values) => {
      props.onAddressSelected(values)
    }}
    renderInput={(params) => (
      <EditText
        {...params}
        className="bg-inputBg rounded-lg"
        placeholder={props.hint}
        margin="dense"
        size="small"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {props.loading ? <CircularProgress className="mr-8" color="inherit" size={20}/> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
  />;
}
