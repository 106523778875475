import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import {useList} from "react-firebase-hooks/database";
import firebaseApp from "../../services/Firebase";
import {AppBarCustom} from "../../components/AppBarCustom";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {RideMateListShimmer} from "../home/RideMateList";


const Messages = () => {

    const uid = localStorage.getItem("id")
    const database = firebaseApp.database();
    let navigate = useNavigate();
    const [snapshots, chatLoading, chatError] = useList(database.ref(`chat_personal/chats/${uid}`).orderByChild("created_at").limitToLast(100));

    useEffect(() => {
        if (firebaseApp.auth().currentUser == null)
            firebaseApp.auth()
                .signInWithCustomToken(localStorage.getItem("firebase_token") || "")
                .then((userCredential) => {
                })
    }, [])

    useEffect(() => {
        console.log(JSON.stringify(snapshots))
        console.log(snapshots?.length)
    }, [snapshots?.length])


    return (
        <div>
            <Helmet>
                <title>Messages</title>
            </Helmet>

            <AppBarCustom title={"Messages"} onClick={() => {
                navigate(-1);
            }}/>

            <main className="mt-16">

                <div>
                    <div>
                        {chatError && <strong>Error: {chatError}</strong>}

                        {!chatLoading && snapshots?.length === 0 &&
                            <div className="text-center mt-28">

                                <img className="mx-auto px-24 mt-8 w-auto" src={"/images/ic_no_message.svg"}/>
                                <div className="text-lg mt-6 px-10 text-black">You haven't received any messages yet!<br/>Try texting your ride mates for faster ride.</div>

                            </div>
                        }

                        <ul className="flex flex-col-reverse pt-2 pb-20">
                            {chatLoading &&
                                [...Array(5)].map((_, i) =>
                                    <div className="flex ml-4 mb-6 mr-4 flex-row items-center min-w-0">
                                        <div className="w-14 h-14 rounded-full bg-fafafa"/>
                                        <div
                                            className="ml-3 w-auto flex-1 overflow-hidden text-ellipsis overflow-ellipsis">
                                            <div className="flex w-full justify-between items-center">
                                                <div className="w-32 h-4 pr-2 bg-fafafa text-fafafa rounded">-</div>
                                                <div className="flex-1"/>
                                                <div className="w-20 h-4 text-sm bg-fafafa text-fafafa rounded">-</div>
                                            </div>
                                            <div className="w-44 h-4 mt-2 block bg-fafafa text-fafafa rounded">-</div>
                                        </div>
                                    </div>
                                )}

                            {!chatLoading && snapshots &&
                                snapshots.map((chat, index) => (chat.val().name &&
                                    <div className="flex ml-4 mb-6 mr-4 flex-row items-center min-w-0"
                                         onClick={() => {
                                             navigate('/chat', {
                                                 state: {
                                                     uid: chat.val()?.uid,
                                                     name: chat.val()?.name,
                                                     picture: chat.val()?.picture
                                                 }
                                             })
                                         }}>
                                        <img className="w-14 h-14 rounded-full bg-f1f3f4 object-cover"
                                             onError={e => e.currentTarget.src = '/images/user_placeholder2.png'}
                                             src={"https://images.ridemate.xyz/profile_picture/" + chat.val().picture}/>
                                        <div
                                            className="ml-3 w-auto flex-1 overflow-hidden text-ellipsis overflow-ellipsis">
                                            <div className={!chat.val().seen ? "font-medium" : ""}>
                                                <div className="flex w-full justify-between items-center">
                                                    <div
                                                        className="overflow-hidden text-ellipsis overflow-ellipsis whitespace-nowrap flex-1 pr-2">
                                                        {chat.val().name}
                                                    </div>
                                                    <div className="text-sm text-777">
                                                        {moment(chat.val().created_at).fromNow().replace("now ago", "now")}
                                                    </div>
                                                </div>

                                                <div
                                                    className="block w-full min-w-0 overflow-hidden text-ellipsis overflow-ellipsis whitespace-nowrap flex-1 text-555">
                                                    {chat.val().sender === uid ? "You: " : ""}{chat.val().message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </ul>

                    </div>

                </div>
            </main>
        </div>
    );
}

export default Messages;