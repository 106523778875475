import moment from 'moment/moment';

export function validateEmail(value) {
    let input = document.createElement('input');
    input.type = 'email';
    input.required = true;
    input.value = value;
    return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
}

export function isSameDay(date1, date2): boolean {
    return moment(date1).date() === moment(date2).date()
        && moment(date1).month() === moment(date2).month()
        && moment(date1).year() === moment(date2).year()
}

export function delay(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

export function formatDistance(distance: number) {
    if (distance > 1000)
        return ~~(distance / 1000) + "km"
    else
        return ~~(distance) + "m"
}

export function afterNow(time) {
    return new Date(time) > new Date(); // true if time1 is later
}

export function beforeNow(time) {
    return new Date(time) < new Date(); // true if time1 is later
}


export function formatLeavingAt(date) {
    const MINUTE = 60,
        HOUR = MINUTE * 60,
        DAY = HOUR * 24,
        WEEK = DAY * 7,
        MONTH = DAY * 30,
        YEAR = DAY * 365

    const secondsAgo = Math.round((+new Date(date) - new Date().getTime()) / 1000)

    if (secondsAgo < MINUTE) {
        return "in " + ~~secondsAgo + " seconds"
    } else if (secondsAgo < HOUR) {
        return "in " + ~~(secondsAgo / MINUTE) + " minutes"
    } else {
        let today = moment();
        let tomorrow = moment().add(1, 'day');
        if (moment(date).isSame(today, 'day'))
            return "at " + moment(date).format('h:mm A')
        else if (moment(date).isSame(tomorrow, 'day'))
            return "tomorrow at " + moment(date).format('h:mm A')
        else
            return "at " + moment(date).format('h:mm A on DD MMM')
    }

}