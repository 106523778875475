import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import {getMessaging, getToken, onMessage} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBpyftbo3QOwA8ofGveFgI2GL5BwLhMTlM",
    authDomain: "hmtsoft-2da3d.firebaseapp.com",
    databaseURL: "https://hmtsoft-2da3d-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "hmtsoft-2da3d",
    storageBucket: "hmtsoft-2da3d.appspot.com",
    messagingSenderId: "744736255248",
    appId: "1:744736255248:web:a2629bb058bbf31a034995",
    measurementId: "G-QK7FZ46GZ7",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);

onMessage(messaging, (payload) => {
    console.log('Message received Test. ', payload);
});


export const getFcmToken = getToken(messaging, {vapidKey: 'BDWts4Y-o5jN-wz0M4MCwfIj3Plb9bnyeWppZyYIdtLNqoth9Z4wZUbWzQQxKAuZTxcn3_1QzzLg9NXlIer_8B8'});

export default firebaseApp