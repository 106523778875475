import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", (event) => {
//         const sw = event?.target as ServiceWorker;
//         console.log(sw.state)
//         if (sw.state === "activated")
//           window.location.reload()
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//     onServiceWorkerUpdate(registration)
//   }
// })

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version is available! Refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
};

serviceWorkerRegistration.register(configuration);
