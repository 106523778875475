import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import Home from './pages/home/Home';
import Account from './pages/account/Account';
import MyMates from './pages/my-mates/MyMates';
import Messages from './pages/messages/Messages';
import ForgotPassword from './pages/auth/ForgotPassword';
import Landing from './pages/landing/Landing';
import PrivacyPolicy from './pages/others/PrivacyPolicy';
import TermsConditions from './pages/others/TermsConditions';
import Schedules from './pages/schedules/Schedules';
import VerifyOtp from './pages/auth/VerifyOtp';
import EditProfile from './pages/auth/EditProfile';
import ResetPassword from './pages/auth/ResetPassword';
import IOSInstallation from './pages/others/iOSInstallation';
import ChatRoom from "./pages/chatroom/ChatRoom";
import ChatPersonal from "./pages/chat/ChatPersonal";
import AndroidInstallation from "./pages/others/AndroidInstallation";


const AppRoutes = () => {
    let auth = !!localStorage.getItem("access_token");

    return (<Routes>
            {/*<Route path="/" element={<Home/>}/>*/}
            <Route path="/" element={auth ? <Home/> : <Landing/>}/>
            <Route path="/landing" element={<RequireAuth><Landing/></RequireAuth>}/>
            <Route path="/home" element={<RequireAuth><Home/></RequireAuth>}/>
            <Route path="/login" element={<RequireGuest><Login/></RequireGuest>}/>
            <Route path="/forgot-password" element={<RequireGuest><ForgotPassword/></RequireGuest>}/>
            <Route path="/signup" element={<RequireGuest><Signup/></RequireGuest>}/>
            <Route path="/account" element={<RequireAuth><Account/></RequireAuth>}/>
            <Route path="/my-mates" element={<RequireAuth><MyMates/></RequireAuth>}/>
            <Route path="/schedules" element={<RequireAuth><Schedules/></RequireAuth>}/>
            <Route path="/messages" element={<RequireAuth><Messages/></RequireAuth>}/>
            <Route path="/chatroom" element={<RequireAuth><ChatRoom/></RequireAuth>}/>
            <Route path="/chat" element={<RequireAuth><ChatPersonal/></RequireAuth>}/>
            <Route path="/verify-otp" element={<RequireAuth><VerifyOtp/></RequireAuth>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/edit-profile" element={<RequireAuth><EditProfile/></RequireAuth>}/>
            <Route path="/ios-installation" element={<IOSInstallation/>}/>
            <Route path="/android-installation" element={<AndroidInstallation/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/terms-and-conditions" element={<TermsConditions/>}/>
        </Routes>
    );
}

function RequireAuth({children}: { children: JSX.Element }) {
    let auth = !!localStorage.getItem("access_token");
    let location = useLocation();
    if (!auth) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
}

function RequireGuest({children}: { children: JSX.Element }) {
    let auth = !!localStorage.getItem("access_token");
    let location = useLocation();
    if (auth) {
        return <Navigate to="/" state={{from: location}} replace/>;
    }
    return children;
}

export default AppRoutes;