import styled from '@emotion/styled';
import { TextField } from '@mui/material';


export  const EditText = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '0.5rem',
    borderColor: '#F2F4F7',
  },
  'input': {

  }
}));