import React from "react";
import { Helmet } from 'react-helmet';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';
import { AppBarCustom } from '../../components/AppBarCustom';



const MyMates = () => {
  let navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>My Mates</title>
      </Helmet>
      <AppBarCustom title={"My Mates"} onClick={() => {
        navigate(-1);
      }}/>

      <main className="mt-20">

        <FiUsers className="mx-auto h-40 w-40 text-gray-300 mt-24"/>

        <div className="text-center h-96 align-middle text-xl mt-5">No ride mates yet</div>

      </main>

    </div>
  );
}

export default MyMates;