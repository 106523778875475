import {RideMateResponse} from "../../models/RideMateResponse";
import {beforeNow, formatDistance, formatLeavingAt} from "../../utils/Utils";
import React from "react";
import {toast} from 'react-toastify';

export function RideMateList(props: { item: RideMateResponse, onItemClick: any }) {

    return <div className="flex mb-3 cursor-grab" onClick={() => props.onItemClick(props.item)}>
        <div className="relative">
            <img className="w-12 h-12 rounded-full bg-f1f3f4 object-cover"
                 onError={e => e.currentTarget.src = '/images/user_placeholder2.png'}
                 src={"https://images.ridemate.xyz/profile_picture/" + props.item.picture}/>
            {props.item.vehicle_owner !== "no" &&
                <span
                    className="absolute mx-auto text-center -mt-px20 left-0 right-0">
                                        <span
                                            className={(props.item.vehicle_owner === "bike" ? "bg-pink" : "bg-secondary") + " text-white px-1.5 pt-0.5 pb-0.5 text-xxs rounded uppercase font-medium"}>
                                            {props.item.vehicle_owner}
                                        </span>
                                    </span>
            }
        </div>

        <div className="ml-3 flex-1">
            <div className="font-medium -mt-1">{props.item.name} </div>
            <div className="text-sm text-444">
                {props.item.scheduled_at === null || beforeNow(props.item.scheduled_at) ?
                    <div className="text-online">Leaving now</div>
                    : "Leaving " + formatLeavingAt(props.item.scheduled_at)}
            </div>
            <div className="text-sm text-444"><span
                className="font-medium"> {formatDistance(props.item.home_distance)} </span> {"• " +
                props.item.home_address + ", " + props.item.home_upazila_name}</div>

            <div className="h-px bg-f7f7f7 mt-3 mr-3"/>
        </div>
    </div>;
}

export function RideMateListShimmer() {
    return <div className="flex mb-3 cursor-grab">
        <div className="relative">
            <div className="w-12 h-12 rounded-full bg-f7f7f7 rounded-full"/>
        </div>

        <div className="ml-3 flex-1">
            <div className="font-medium -mt-1 text-f7f7f7">
                <span className="bg-f7f7f7 rounded">----------------</span>
            </div>
            <div className="text-sm text-f7f7f7">
                <span className="bg-f7f7f7 rounded">-------------</span></div>
            <div className="font-medium text-sm text-f7f7f7">
                <span className="bg-f7f7f7 rounded">--------------------------------</span></div>
            <div className="h-px bg-f7f7f7 mt-3 mr-3"/>
        </div>
    </div>;
}