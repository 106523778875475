import React, { useEffect, useState } from 'react'

import MapPicker from 'react-google-map-picker'
import { Button, Dialog } from '@mui/material';
import { RestartAlt } from '@mui/icons-material';

const DefaultZoom = 13;

export const LocationPicker = (props: { lat: number, lng: number, show: boolean, onDismiss: any, onLatLngSelected: any }) => {
  const DefaultLocation = {
    lat: props.lat,
    lng: props.lng,
  }
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  useEffect(() => {
    setDefaultLocation({ lat: props.lat, lng: props.lng });
    setZoom(DefaultZoom);
  }, [props.lat, props.lng])

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  return (
    <>
      <Dialog
        fullScreen={true}
        open={props.show}
        onClose={() => {
          props.onDismiss()
        }}
        closeAfterTransition={true}
        keepMounted={false}
      >

        <MapPicker
          defaultLocation={defaultLocation}
          zoom={zoom}
          style={{
            height: '100%',
            hide: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
            ]
          }}
          onChangeLocation={handleChangeLocation}
          onChangeZoom={handleChangeZoom}
          apiKey='AIzaSyAxMBrx6t9pYN3bYlAmZg1tmpp50AjPf6U'/>

        <div className="flex flex-row mt-3 mb-3">
          <Button
            className="ml-4 mr-1.5 text-black rounded-lg h-12 shadow-none"
            color="info"
            variant="contained"
            onClick={() => props.onDismiss()}
          >Cancel</Button>

          <Button
            className="ml-1.5 mr-1.5 text-black rounded-lg h-12 shadow-none"
            color="info"
            variant="contained"
            onClick={handleResetLocation}
          ><RestartAlt/></Button>

          <Button
            className="flex-1 ml-1.5 mr-4 rounded-lg h-12 shadow-none"
            color="secondary"
            onClick={() => {
              props.onLatLngSelected(location)
              props.onDismiss()
            }}
            variant="contained">Confirm</Button>
        </div>

      </Dialog>
    </>
  )
}