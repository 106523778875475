import {AddressAutoComplete} from './AddressAutoComplete';
import React, {useEffect, useState} from 'react';
import {Upazila, UpazilaResponse} from '../change-status/ChangeStatusModal';
import {Global} from '@emotion/react';
import {Puller} from '../../components/Puller';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Button} from '@mui/material';
import {EditText} from '../../components/EditText';
import {Room} from '@mui/icons-material';
import {LocationPicker} from '../change-status/LocationPicker';
import {toast} from 'react-toastify';
import {getUpazilas} from "../../services/ApiService";


const HomeAddressModal = (props: { show: boolean, onDismiss: any, window?: () => Window }) => {
    const {window} = props;
    const [open, setOpen] = useState(false);
    const [locationPickerOpen, setLocationPickerOpen] = useState(false);
    const [selectedLatLng, setSelectedLatLng] = useState({lat: 23.8103, lng: 90.4125})
    const [options, setOptions] = useState<Upazila[]>([]);
    const loading = open && options.length === 0;
    const [mapUsed, setMapUsed] = useState(false);
    const [upazilaName, setUpazilaName] = useState("")
    const [upazilaId, setUpazilaId] = useState(0)
    const [addressLine, setAddressLine] = useState("")

    useEffect(() => {
        const userAddress = localStorage.getItem("user_home");
        if (userAddress != null) {
            const json = JSON.parse(userAddress)
            if (json != null) {
                setSelectedLatLng({
                    lat: json.lat,
                    lng: json.lng
                })
                setMapUsed(json.map_used)
                setUpazilaName(json.upazila_name)
                setUpazilaId(json.upazila_id)
                setAddressLine(json.address_line)
            }
        }
    }, [])

    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            await getUpazilas()
                .then((response: { data: UpazilaResponse }) => {
                    if (active) {
                        setOptions(response.data.data);
                    }
                });
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        overflow: 'visible',
                    },
                }}
            />

            <SwipeableDrawer
                variant="temporary"
                container={container}
                anchor="bottom"
                open={props.show}
                onClose={() => {
                    props.onDismiss()
                }}
                closeAfterTransition={true}
                onOpen={() => {
                }}
                disableSwipeToOpen={true}
                keepMounted={false}
            >
                <Puller/>
                <div className="px-5 mt-4 mb-5">
                    <div className="text-xl font-bold mb-4">Update your home address</div>
                    <div className="font-medium text-black">Select Upazila</div>
                    <AddressAutoComplete
                        onAddressSelected={(value) => {
                            setUpazilaName(value.name)
                            setUpazilaId(value.id)
                            setMapUsed(false)
                            setSelectedLatLng({lat: value.lat, lng: value.lng})
                        }}
                        hint="Search here"
                        defaultValue={upazilaName}
                        open={open}
                        onOpen={() => {
                            setOpen(true)
                        }}
                        onClose={() => setOpen(false)}
                        options={options} loading={loading}
                    />


                    <div className="font-medium text-black mt-2">Road, Block/Sector, Area</div>
                    <div className="w-auto -mt-1">
                        <EditText
                            variant="outlined"
                            className="bg-inputBg rounded-lg w-full"
                            size="small"
                            margin="dense"
                            placeholder="eg. Road 6, Sector 7"
                            value={addressLine}
                            onChange={e => setAddressLine(e.target.value)}
                        />
                    </div>

                    <div className="text-666 text-[12px]">* Flat or house no are not needed</div>

                    <Button
                        className="input-btn-light rounded-lg bg-inputBg mt-4 normal-case"
                        startIcon={<Room/>}
                        color="secondary"
                        onClick={() => {
                            if (upazilaName !== "")
                                setLocationPickerOpen(true)
                            else {
                                toast.warn("Please select Upazila first")
                            }
                        }}>
                        Select location from Google Maps
                    </Button>
                    <div className="text-666 text-[12px]">* It's needed to show you the nearest ride mates first</div>
                </div>

                {locationPickerOpen &&
                    <LocationPicker
                        show={locationPickerOpen}
                        lat={selectedLatLng.lat}
                        lng={selectedLatLng.lng}
                        onLatLngSelected={(location) => setSelectedLatLng(location)}
                        onDismiss={() => setLocationPickerOpen(false)}/>
                }

                <div className="flex flex-row">
                    <Button
                        className="flex-1 ml-5 mr-1.5 mb-6 text-black rounded-lg h-12 shadow-none"
                        color="info"
                        variant="contained"
                        onClick={() => {
                            props.onDismiss()
                        }}
                    >Cancel</Button>

                    <Button
                        className="flex-1 ml-1.5 mr-5 mb-6 rounded-lg h-12 shadow-none"
                        color="secondary"
                        onClick={() => {
                            if (upazilaName === "")
                                toast.error("Please select Upazila")
                            else if (addressLine === "")
                                toast.error("Please enter address")
                            else {
                                localStorage.setItem("user_home", JSON.stringify(
                                    {
                                        upazila_id: upazilaId,
                                        upazila_name: upazilaName,
                                        address_line: addressLine,
                                        map_used: true,
                                        lat: selectedLatLng.lat,
                                        lng: selectedLatLng.lng
                                    }
                                ))
                                props.onDismiss()
                            }
                        }}
                        variant="contained">Confirm</Button>
                </div>

            </SwipeableDrawer>
        </>
    );
}

export default HomeAddressModal;